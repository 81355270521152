import { useIntl } from '@cookbook/solid-intl';
import {
  arrayToObjectByKey,
  deepExtendObjects,
  flattenObject
} from '~/utils/commonUtils.ts';
import defaultTranslations from './translations/default.json';
import { timeSignatures } from '~/consts.ts';

export enum SUPPORTED_LOCALES {
  enUS = 'en-US',
  esES = 'es-ES'
}

export const DEFAULT_LOCALE: string = SUPPORTED_LOCALES.enUS;

const flattenedDefaultTranslations = flattenObject(defaultTranslations);

async function loadTranslations(locale: string) {
  const supportedLocaleList: Array<string> = Object.values(SUPPORTED_LOCALES);
  if (!supportedLocaleList.includes(locale)) {
    console.error(
      `Invalid locale : ${locale}. Falling back to ${DEFAULT_LOCALE}`
    );
    return flattenedDefaultTranslations;
  }
  try {
    let translations = (
      await import(`./translations/${locale.toLowerCase()}.json`)
    ).default;
    translations = deepExtendObjects(defaultTranslations, translations);
    return flattenObject(translations);
  } catch (e) {
    console.error(
      `Failed to fetch translations for locale ${locale}. Falling back to ${DEFAULT_LOCALE}`
    );
    return flattenedDefaultTranslations;
  }
}

function getLocalizedString(
  translationKey: string,
  messageVars?: Record<string, any>,
  defaultMessage?: string
): string {
  // intl (i.e. useIntl()) should be passed as a key messageVars
  // for out of the context execution
  // e.g. in case of api response handlers, event handlers etc
  const { intl, ...vars } = messageVars || {};
  const newIntl = intl || useIntl();
  // we should allow empty string as default message
  const defaultMessageStr =
    defaultMessage === undefined || defaultMessage === null
      ? flattenedDefaultTranslations[translationKey] || translationKey
      : defaultMessage;
  return newIntl.formatMessage(
    {
      id: translationKey,
      // defaultMessage is already taken care of in loadTranslations in utils.ts
      // here it is for redundancy
      defaultMessage: defaultMessageStr
    },
    vars
  );
}

type RelativeTimeUnit =
  | 'second'
  | 'minute'
  | 'hour'
  | 'day'
  | 'week'
  | 'month'
  | 'quarter'
  | 'year';

function getDiffByUnit(diffInMs: number): {
  diff: number | null;
  unit: RelativeTimeUnit | null;
} {
  const isNegative = diffInMs < 0;
  const positiveDiffInMs = Math.abs(diffInMs);
  let diff;
  let unit;
  if (positiveDiffInMs < timeSignatures.MIN_IN_MILLIS) {
    diff = Math.floor(positiveDiffInMs / timeSignatures.SEC_IN_MILLIS);
    unit = 'second';
  } else if (positiveDiffInMs < timeSignatures.HOUR_IN_MILLIS) {
    diff = Math.floor(positiveDiffInMs / timeSignatures.MIN_IN_MILLIS);
    unit = 'minute';
  } else if (positiveDiffInMs < timeSignatures.DAY_IN_MILLIS) {
    diff = Math.floor(positiveDiffInMs / timeSignatures.HOUR_IN_MILLIS);
    unit = 'hour';
  } else if (positiveDiffInMs < timeSignatures.MONTH_IN_MILLIS) {
    diff = Math.floor(positiveDiffInMs / timeSignatures.DAY_IN_MILLIS);
    unit = 'day';
  } else if (positiveDiffInMs < timeSignatures.YEAR_IN_MILLIS) {
    diff = Math.floor(positiveDiffInMs / timeSignatures.MONTH_IN_MILLIS);
    unit = 'month';
  } else if (positiveDiffInMs < 100 * timeSignatures.YEAR_IN_MILLIS) {
    // Show years till 100 years
    diff = Math.floor(positiveDiffInMs / timeSignatures.YEAR_IN_MILLIS);
    unit = 'year';
  } else {
    // case of never where diff in mins is more than 100 years
    return {
      diff: null,
      unit: null
    };
  }

  return {
    diff: isNegative ? -1 * diff : diff,
    unit: unit as RelativeTimeUnit
  };
}

function getRelativeTime(time: string | Date): string {
  const newIntl = useIntl();
  if (time === '') {
    console.error(
      'Failed to format relative time, param timeStr is empty string'
    );
    return '';
  }
  const dateObj = time instanceof Date ? time : new Date(time);
  const { diff, unit } = getDiffByUnit(
    dateObj.getTime() - new Date().getTime()
  );

  if (diff === null) {
    return `-  ${getLocalizedString('commons.relative_time_never')}`;
  }

  return newIntl.formatRelativeTime(diff, unit, {
    numeric: 'auto',
    style: 'long'
  });
}

function getFormattedDate(
  time: string | Date,
  {
    includeTime = false
  }: {
    includeTime?: boolean;
  } = {}
): string {
  const newIntl = useIntl();
  if (time === '') {
    console.error('Failed to format date, param timeStr is empty string');
    return '';
  }
  const dateObj = time instanceof Date ? time : new Date(time);

  const dateParts = arrayToObjectByKey(
    newIntl.formatTimeToParts(dateObj, {
      year: 'numeric',
      day: '2-digit',
      month: 'short',
      formatMatcher: 'basic',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
      dayPeriod: 'long'
    }),
    'type'
  );

  // @todo revisit the date format
  const dateStr = `${dateParts.day.value}-${dateParts.month.value}-${dateParts.year.value}`;

  return !includeTime
    ? `${dateStr}`
    : `${dateStr} ${dateParts.hour.value}:${dateParts.minute.value}${dateParts.dayPeriod.value.toLowerCase()}`;
}

export {
  loadTranslations,
  getLocalizedString,
  getRelativeTime,
  getFormattedDate
};
