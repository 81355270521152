import { JSXElement, mergeProps, Show } from 'solid-js';
import { EnvironmentTypes } from '~/api';
import { LucideCodeXml, LucideRocket } from 'lucide-solid';
import { twMerge } from 'tailwind-merge';

type EnvironmentIconProps = {
  environmentType: EnvironmentTypes;
  class?: string;
  iconWidth?: number;
};

export default function EnvironmentIcon(
  props: EnvironmentIconProps
): JSXElement {
  const merged = mergeProps(
    {
      class: ''
    },
    props
  );

  const iconProps: { width: number; strokeWidth: number; class: string } = {
    width: merged.iconWidth || 16,
    strokeWidth: 1.25,
    class: `text-fg-default`
  };

  return (
    <div
      class={twMerge(
        `flex p-1.5 rounded-sm justify-center items-center`,
        merged.class
      )}
      classList={{
        'bg-[#30A46C4D]':
          merged.environmentType === EnvironmentTypes.PRODUCTION,
        'bg-[#FFAA0080]': merged.environmentType !== EnvironmentTypes.PRODUCTION
      }}
    >
      <Show
        when={merged.environmentType !== EnvironmentTypes.PRODUCTION}
        fallback={<LucideRocket {...iconProps} />}
      >
        <LucideCodeXml {...iconProps} />
      </Show>
    </div>
  );
}
