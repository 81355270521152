enum EnvironmentRegions {
  UNSPECIFIED = 'REGION_CODE_UNSPECIFIED',
  US = 'US',
  EU = 'EU'
}

export enum EnvironmentTypes {
  UNSPECIFIED = 'ENVIRONMENT_TYPE_UNSPECIFIED',
  PRODUCTION = 'PRD',
  TESTING = 'TST',
  DEVELOPMENT = 'DEV'
}

export enum CustomDomainStatuses {
  UNSPECIFIED = 'UNSPECIFIED',
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  FAILED = 'FAILED'
}
export interface EnvironmentData {
  id: string;
  createTime?: string;
  updateTime?: string;
  displayName: string;
  domain?: string;
  url?: string;
  regionCode: EnvironmentRegions;
  type: EnvironmentTypes;
  customDomain: string;
  customDomainStatus: CustomDomainStatuses;
}

export interface CreateEnvironmentRequest {
  displayName: string;
  type: EnvironmentTypes;
}

export interface EnvironmentUpdateRequest {
  displayName: string;
}
