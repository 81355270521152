import { ErrorBoundary, JSXElement, Match, mergeProps, Switch } from 'solid-js';
import ContactSupport from '~/components/ErrorBoundaries/ContactSupport.tsx';
import { logErrorToSentry } from '~/utils/productLogging/sentry.ts';
import { AppErrors } from '~/components/ErrorBoundaries/utils.ts';
import PortalLinkExpired from '~/components/ErrorBoundaries/PortalLinkExpired.tsx';

type AppErrorBoundaryProps = {
  children: JSXElement;
};

export default function AppErrorBoundary(
  props: AppErrorBoundaryProps
): JSXElement {
  const merged = mergeProps({}, props);
  return (
    <ErrorBoundary
      fallback={(err) => {
        logErrorToSentry(err);
        console.log(err);
        return (
          <Switch>
            <Match
              when={
                err instanceof Error &&
                err.message === AppErrors.CUSTOMER_PORTAL_LINK_EXPIRED
              }
            >
              <PortalLinkExpired />
            </Match>
            <Match when={true}>
              <ContactSupport showLogo={true} />;
            </Match>
          </Switch>
        );
      }}
    >
      {merged.children}
    </ErrorBoundary>
  );
}
