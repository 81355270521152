import { createSignal, JSXElement, mergeProps, Show } from 'solid-js';
import { CustomerPortalHeader } from '~/components/Layout/Header';
import { isLoadedInIframe } from '~/utils/commonUtils.ts';
import { CustomerPortalPageTypes } from '~/components/Layout/utils.tsx';
import TwoColumnDraggableContainer from '~/components/Layout/TwoColumnDraggableContainer.tsx';
import NeedHelpDocContent from '~/components/Layout/NeedHelp/NeedHelpDocContent.tsx';

type CustomerPortalLayoutProps = {
  children: JSXElement;
  pageType?: CustomerPortalPageTypes;
};

export default function AdminPortalLayout(
  props: CustomerPortalLayoutProps
): JSXElement {
  const merged = mergeProps(
    {
      pageType: CustomerPortalPageTypes.SSO_CONFIGURATION
    },
    props
  );

  const [showHelpSection, setShowHelpSection] = createSignal(false);

  const isRenderingDocs = () =>
    merged.pageType === CustomerPortalPageTypes.DOCUMENTATION;

  return (
    <div class={`flex justify-center`}>
      <TwoColumnDraggableContainer
        leftContent={
          <div
            class={`min-h-screen mx-auto bg-bg-default border-r border-r-border-muted border-l border-l-border-muted`}
            classList={{
              'px-8': !isRenderingDocs(),
              'w-8/12': !isRenderingDocs() && !showHelpSection(),
              'container': !isRenderingDocs() && showHelpSection(),
              'w-full': isRenderingDocs()
            }}
          >
            <Show when={!isLoadedInIframe()}>
              <CustomerPortalHeader
                pageType={merged.pageType}
                isHelpDisplayed={showHelpSection()}
                onClickNeedHelp={() =>
                  setShowHelpSection((isHelpDisplayed) => !isHelpDisplayed)
                }
              />
            </Show>
            <div
              classList={{
                'pt-8 pb-4': !isRenderingDocs(),
                'w-full': isRenderingDocs()
              }}
              class={`mx-auto`}
            >
              {merged.children}
            </div>
          </div>
        }
        rightContent={showHelpSection() && <NeedHelpDocContent />}
      />
    </div>
  );
}
