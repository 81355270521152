import { JSXElement, mergeProps } from 'solid-js';
import { getSlug } from '~/components/AppRouter/utils.ts';
import PortalContainer from '~/components/AppContainer/PortalContainer.tsx';
import WorkspaceContainer from '~/components/AppContainer/WorkspaceContainer.tsx';
import { ToastContextProvider } from '~/components/ui/Toast';
import { globalStore } from '~/stores';
import { AppContexts } from '~/utils/commonUtils.ts';

type AppContainerProps = {
  children: JSXElement;
};

export default function AppContainer(props: AppContainerProps): JSXElement {
  const merged = mergeProps({}, props);
  let Container = (props: { children: JSXElement }) => <>{props.children}</>;

  if (
    window.location.pathname.startsWith(
      getSlug.customerPortalSSOBaseNamespace()
    )
  ) {
    Container = PortalContainer;
  } else if (
    window.location.pathname.startsWith(getSlug.workspaceBaseNamespace())
  ) {
    Container = WorkspaceContainer;
  }

  switch (globalStore.appContext) {
    case AppContexts.CUSTOMER_PORTAL:
      Container = PortalContainer;
      break;
    case AppContexts.HANDLERS:
      // No separate container needed so far for handlers
      break;
    case AppContexts.SCALEKIT_DASHBOARD:
    default:
      Container = WorkspaceContainer;
  }

  return (
    <Container>
      <ToastContextProvider>{merged.children}</ToastContextProvider>
    </Container>
  );
}
