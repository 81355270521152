import { globalStore } from '~/stores';

const logger = {
  info: (...params) => {
    globalStore.debugModeEnabled && console.log(...params);
  },
  error: (...params) => {
    globalStore.debugModeEnabled && console.error(...params);
  }
};

export default logger;
