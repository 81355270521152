import { JSXElement, mergeProps, Show } from 'solid-js';
import { XCircleIcon } from 'lucide-solid';
import { Button } from '~/components/ui/Button';
import ScalekitLogo from '~/assets/scalekit-logo-dark.svg';
import { Localized } from '~/i18n';
import { miscConstants } from '~/consts.ts';
import { AppContexts, getAppContext, mergeEmailParts } from '~/utils/commonUtils.ts';

type ContactSupportProps = {
  showLogo?: boolean;
};

const getTranslationKey = (key) => `pages.contact_support.${key}`;

const supportEmail = mergeEmailParts([miscConstants.supportEmail,miscConstants.supportEmailDomain])

export default function ContactSupport(props: ContactSupportProps): JSXElement {
  const merged = mergeProps(
    {
      showLogo: false
    },
    props
  );
  return (
    <>
      <div class={`flex flex-col w-full justify-center items-center`}>
        <XCircleIcon
          width={48}
          height={48}
          strokeWidth={1}
        />
        <p class={`mt-10 text-xl`}>
          <Localized translationKey={getTranslationKey('failed_to_load')} />
        </p>
        <p class={`mt-3`}>
          <Localized
            translationKey={getTranslationKey('error_while_fetching')}
          />
        </p>
        <p>
          <Localized translationKey={getTranslationKey('try_refreshing')} />
        </p>
        <Show when={getAppContext() === AppContexts.SCALEKIT_DASHBOARD}>
          <p class={`mt-6`}>
            <Localized
              translationKey={getTranslationKey('email_support_team')}
              vars={{
                supportEmail,
                mailToSupport: () => (
                  <a
                    class={`text-link-primary`}
                    href={`mailto:${supportEmail}`}
                  >
                    {supportEmail}
                  </a>
                )
              }}
            />
          </p>

          <Button
            class={'mt-10'}
            variant={'outline'}
            size={'sm'}
          >
            <a href={`mailto:${supportEmail}`}>
              <Localized
                translationKey={getTranslationKey('cta_label_contact_support')}
              />
            </a>
          </Button>
          <Show when={merged.showLogo}>
            <img
              class={`mt-10 w-[132px]`}
              src={ScalekitLogo}
              alt={'Scalekit'}
            />
          </Show>
        </Show>
      </div>
    </>
  );
}
