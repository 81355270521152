import { JSXElement, mergeProps } from 'solid-js';
import CustomerPortalApiConnector from '~/api/CustomerPortalApiConnector.tsx';
import { ConfirmModalProvider } from '~/components/ui/Modal';
import PortalAuthentication from '~/components/AppContainer/PortalAuthentication.tsx';
import PortalCustomizations from '../../portal-customization/PortalCustomizations.tsx';
import NeedHelpContextProvider from '~/components/Layout/NeedHelp/NeedHelpContext.tsx';

type PortalContextProviderProps = {
  children: JSXElement;
};

export default function PortalContainer(
  props: PortalContextProviderProps
): JSXElement {
  const merged = mergeProps({}, props);

  return (
    <PortalAuthentication>
      <NeedHelpContextProvider>
        <PortalCustomizations>
          <ConfirmModalProvider>
            <CustomerPortalApiConnector>
              {merged.children}
            </CustomerPortalApiConnector>
          </ConfirmModalProvider>
        </PortalCustomizations>
      </NeedHelpContextProvider>
    </PortalAuthentication>
  );
}
