import { createContext, createEffect, JSXElement, useContext } from 'solid-js';
import { createStore } from 'solid-js/store';
import { IntlProvider } from '@cookbook/solid-intl';
import { DEFAULT_LOCALE, loadTranslations } from './utils.ts';
import { devConfig, INTL_LOG_MODES } from '~/consts.ts';

const LanguageContext = createContext();

type LanguageProviderProps = {
  children?: JSXElement;
};

export type LanguageContextValue = {
  locale: string;
  translations: Record<string, any> | null;
  setLocale: (locale: string) => void;
};

export const LanguageProvider = (props: LanguageProviderProps): JSXElement => {
  const [contextData, setContextData] = createStore<LanguageContextValue>({
    locale: DEFAULT_LOCALE,
    translations: null,
    setLocale: (locale: string) => {
      setContextData('locale', locale);
    }
  });

  createEffect(() => {
    loadTranslations(contextData.locale).then((translationStr) => {
      setContextData('translations', translationStr);
    });
  });

  return (
    <LanguageContext.Provider value={contextData}>
      <IntlProvider
        locale={contextData.locale}
        messages={contextData.translations}
        onError={(e: Error) => {
          // Supressing unnecessary intl key errors in regular dev mode
          // To enable change intlLogMode to ERROR
          if (devConfig.intlLogMode === INTL_LOG_MODES.ERROR) {
            console.error(e);
          } else if (devConfig.intlLogMode === INTL_LOG_MODES.INFO) {
            console.log(
              `formatJs error (change 'intlLogMode' to ERROR for trace) :: ${e.message}`
            );
          }
        }}
      >
        {props.children}
      </IntlProvider>
    </LanguageContext.Provider>
  );
};

export const useLanguageContext = () =>
  useContext(LanguageContext) as LanguageContextValue;
