import { Component, JSXElement } from 'solid-js';
import { Localized } from '~/i18n';
import {
  Building2Icon,
  FileKey2Icon,
  GemIcon,
  KeyRoundIcon,
  LayoutGridIcon,
  MouseIcon,
  UserRoundIcon,
  WebhookIcon
} from 'lucide-solid';
import {
  EnvironmentPagePath,
  getSlug,
  MyAccountPagePath,
  WorkspacePagePath
} from '~/components/AppRouter/utils.ts';
import { EnvironmentData } from '~/api';
import {
  FeatureFlagKeys,
  FeatureFlagMap
} from '~/api/types/featureFlagData.ts';
import { isFeatureEnabled } from '~/utils/featureFlag.tsx';

export type LeftNavLinkConfig = {
  iconComponent?: Component;
  labelComponent: Component;
  href: string;
  hidden?: boolean;
};

export type LeftNavGroupConfig = {
  labelComponent?: Component;
  items: Array<LeftNavLinkConfig>;
};

export enum LeftNavType {
  WORKSPACE = 'WORKSPACE',
  ENVIRONMENT = 'ENVIRONMENT'
}

const getLabelComponent = ({
  translationKey,
  upperCase = false,
  iconComponent,
  className = ''
}: {
  translationKey: string;
  upperCase?: boolean;
  iconComponent?: Component<{
    width: number;
    strokeWidth?: number;
    class?: string;
  }>;
  className?: string;
}): Component => {
  return (): JSXElement => {
    const IconComponent = iconComponent;
    return (
      <div class={`flex items-center`}>
        {IconComponent && (
          <IconComponent
            width={16}
            strokeWidth={1}
            class={`me-2`}
          />
        )}
        <span class={className}>
          <Localized
            upperCase={upperCase}
            translationKey={translationKey}
          />
        </span>
      </div>
    );
  };
};

const getMenuItemLabelTranslationPath = (translationStr: string) =>
  `page_titles.${translationStr}`;

const getConfigurationGroup = (
  selectedEnvironment: EnvironmentData,
  featureFlags: FeatureFlagMap
): Array<LeftNavGroupConfig> => {
  const selectedEnvironmentId = selectedEnvironment?.id;
  const configurationGroup: LeftNavGroupConfig = {
    labelComponent: getLabelComponent({
      translationKey: 'left_nav_group_labels.configuration',
      upperCase: true
    }),
    items: []
  };
  if (isFeatureEnabled(featureFlags, FeatureFlagKeys.PORTAL_CUSTOMIZATION)) {
    const customizationItem: LeftNavLinkConfig = {
      iconComponent: MouseIcon,
      labelComponent: getLabelComponent({
        translationKey: getMenuItemLabelTranslationPath(`customization`)
      }),
      href: getSlug.environmentById(
        selectedEnvironmentId,
        EnvironmentPagePath.ENVIRONMENT_CUSTOMIZATIONS,
        true
      )
    };
    configurationGroup.items.push(customizationItem);
  }
  if (isFeatureEnabled(featureFlags, FeatureFlagKeys.WEBHOOKS)) {
    const webhookItem: LeftNavLinkConfig = {
      iconComponent: WebhookIcon,
      labelComponent: getLabelComponent({
        translationKey: getMenuItemLabelTranslationPath(`webhooks`)
      }),
      href: getSlug.environmentById(
        selectedEnvironmentId,
        EnvironmentPagePath.ENVIRONMENT_WEBHOOKS,
        true
      )
    };
    configurationGroup.items.push(webhookItem);
  }
  return (
    configurationGroup.items.length ? [configurationGroup] : []
  ) as LeftNavGroupConfig[];
};

const getAppEnvironmentsNavConfig = (
  selectedEnvironment: EnvironmentData,
  featureFlags: FeatureFlagMap
): Array<LeftNavGroupConfig> => {
  const selectedEnvironmentId = selectedEnvironment?.id;

  return [
    {
      items: [
        // {
        //   iconComponent: GanttChartSquareIcon,
        //   labelComponent: getLabelComponent({
        //     translationKey:a
        //       getMenuItemLabelTranslationPath(`environment_overview`)
        //   }),
        //   href: getSlug.environmentById(
        //     selectedEnvironmentId,
        //     EnvironmentPagePath.ENVIROMNEMT_OVERVIEW,
        //     true
        //   )
        // },
        {
          iconComponent: Building2Icon,
          labelComponent: getLabelComponent({
            translationKey: getMenuItemLabelTranslationPath(`organization`)
          }),
          href: getSlug.environmentById(
            selectedEnvironmentId,
            EnvironmentPagePath.ENVIROMNEMT_ORGANIZATIONS,
            true
          )
        }
      ]
    },
    {
      labelComponent: getLabelComponent({
        translationKey: 'left_nav_group_labels.authentication_settings',
        upperCase: true
      }),
      items: [
        {
          iconComponent: LayoutGridIcon,
          labelComponent: getLabelComponent({
            translationKey: getMenuItemLabelTranslationPath(`social_login`)
          }),
          href: getSlug.environmentById(
            selectedEnvironmentId,
            EnvironmentPagePath.ENVIRONMENT_SOCIAL_CONNECTIONS,
            true
          ),
          hidden: !isFeatureEnabled(
            featureFlags,
            FeatureFlagKeys.SOCIAL_CONNECTION
          )
        },
        {
          iconComponent: KeyRoundIcon,
          labelComponent: getLabelComponent({
            translationKey: getMenuItemLabelTranslationPath(`sso`)
          }),
          href: getSlug.environmentById(
            selectedEnvironmentId,
            EnvironmentPagePath.ENVIRONMENT_SSO_CUSTOM_ATTRIBUTES,
            true
          )
        }
      ]
    },
    ...getConfigurationGroup(selectedEnvironment, featureFlags),
    {
      labelComponent: getLabelComponent({
        translationKey: 'left_nav_group_labels.developer_settings',
        upperCase: true
      }),
      items: [
        {
          iconComponent: FileKey2Icon,
          labelComponent: getLabelComponent({
            translationKey: getMenuItemLabelTranslationPath(`api_credentials`)
          }),
          href: getSlug.environmentById(
            selectedEnvironmentId,
            EnvironmentPagePath.ENVIRONMENT_API_CREDENTIALS,
            true
          )
        }
      ]
    }
  ];
};

const getAppWorkspaceNavConfig = (): Array<LeftNavGroupConfig> => {
  return [
    {
      labelComponent: getLabelComponent({
        translationKey: 'left_nav_group_labels.workspace',
        upperCase: false,
        iconComponent: GemIcon,
        className: 'text-sm'
      }),
      items: [
        {
          labelComponent: getLabelComponent({
            translationKey: getMenuItemLabelTranslationPath(`workspace_general`)
          }),
          href: getSlug.workspace(WorkspacePagePath.WORKSPACE_GENERAL, true)
        },
        {
          labelComponent: getLabelComponent({
            translationKey:
              getMenuItemLabelTranslationPath(`manage_environments`)
          }),
          href: getSlug.workspace(
            WorkspacePagePath.WORKSPACE_MANAGE_ENVIRONMENTS,
            true
          )
        },
        {
          labelComponent: getLabelComponent({
            translationKey: getMenuItemLabelTranslationPath(`members`)
          }),
          href: getSlug.workspace(WorkspacePagePath.WORKSPACE_MEMBERS, true)
        }
      ]
    },
    {
      labelComponent: getLabelComponent({
        translationKey: 'left_nav_group_labels.my_account',

        upperCase: false,
        iconComponent: UserRoundIcon,
        className: 'text-sm'
      }),
      items: [
        {
          labelComponent: getLabelComponent({
            translationKey: getMenuItemLabelTranslationPath(`my_profile`)
          }),
          href: getSlug.myAccount(MyAccountPagePath.MYACCOUNT_PROFILE, true)
        }
      ]
    }
  ];
};

export { getAppEnvironmentsNavConfig, getAppWorkspaceNavConfig };
