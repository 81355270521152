import { ark, type HTMLArkProps } from '@ark-ui/solid';
import { splitProps } from 'solid-js';
import { tv, type VariantProps } from 'tailwind-variants';
import { twMerge } from 'tailwind-merge';
import { getAppliedClasses } from '~/utils/commonUtils.ts';

export interface FormLabelProps
  extends FormLabelVariantProps,
    HTMLArkProps<'label'> {}

export const FormLabel = (props: FormLabelProps) => {
  const [variantProps, formLabelProps] = splitProps(props, ['class', 'size']);

  // @ts-expect-error https://github.com/nextui-org/tailwind-variants/issues/145
  const classVariants = styles(variantProps);

  return (
    <ark.label
      class={twMerge(
        classVariants,
        getAppliedClasses(formLabelProps.classList || {})
      )}
      {...formLabelProps}
    />
  );
};

type FormLabelVariantProps = VariantProps<typeof styles>;

const styles = tv({
  base: 'formLabel',
  defaultVariants: { size: 'md' },
  variants: {
    size: {
      sm: 'formLabel--size_sm',
      md: 'formLabel--size_md',
      lg: 'formLabel--size_lg',
      xl: 'formLabel--size_xl'
    }
  }
});
