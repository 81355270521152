import {
  createContext,
  JSXElement,
  mergeProps,
  splitProps,
  useContext
} from 'solid-js';
import { FormRenderFunctionParamType } from '~/components/Form/FelteForm.tsx';

const FormContext = createContext();

type FormContextValueType = FormRenderFunctionParamType;

type FormContextProviderProps = {
  children: JSXElement;
} & FormContextValueType;

const FormContextProvider = (props: FormContextProviderProps): JSXElement => {
  const merged = mergeProps({}, props);
  const [providerProps, createFormProps] = splitProps(merged, ['children']);

  return (
    <FormContext.Provider value={createFormProps}>
      {providerProps.children}
    </FormContext.Provider>
  );
};

const useFormContext = (): FormContextValueType =>
  useContext(FormContext) as FormContextValueType;

export { FormContextProvider, useFormContext };
