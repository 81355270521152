import posthog from 'posthog-js';
import { MemberData } from '~/api';

function initialize() {
  if (import.meta.env.MODE === 'development') {
    return;
  }
  posthog.init(import.meta.env.VITE_POSTHOG_PUBLIC_TOKEN, {
    api_host: 'https://app.posthog.com'
  });
}

function identifyUser(memberData: MemberData) {
  posthog.identify(memberData.id);
}

function resetIdentity() {
  posthog.reset();
}

function getInitializer() {
  let isInitialized = false;
  return () => {
    if (isInitialized) {
      return;
    }
    initialize();
    isInitialized = true;
  };
}

const initPosthog = getInitializer();

export { initPosthog, identifyUser, resetIdentity };
