import { CircleDashedIcon, Loader2Icon, LoaderIcon } from 'lucide-solid';
import styles from './loader.module.scss';
import { mergeProps } from 'solid-js';
import { twMerge } from 'tailwind-merge';

type SkLoaderProps = {
  width?: number;
  fill?: string;
  color?: string;
  strokeWidth?: number;
  class?: string;
  variant?: 'loader' | 'loader-circle' | 'circle-dashed';
};

export default function SkLoader(props: SkLoaderProps) {
  const dimensionProps: { width?: number; height?: number } = {};
  if (props.width) {
    dimensionProps.width = props.width;
    dimensionProps.height = props.width;
  } else {
    dimensionProps.width = 30;
    dimensionProps.height = 30;
  }

  const merged = mergeProps(
    {
      class: '',
      strokeWidth: props.variant === 'loader' ? 2 : 3,
      ...dimensionProps,
      variant: 'loader-circle'
    },
    props
  );
  let Icon = Loader2Icon;
  switch (merged.variant) {
    case 'loader':
      Icon = LoaderIcon;
      break;
    case 'loader-circle':
      Icon = Loader2Icon;
      break;
    case 'circle-dashed':
      Icon = CircleDashedIcon;
  }

  return (
    <div class={`flex flex-col`}>
      <span
        classList={{
          [styles.rotate]: true
        }}
        class={`inline-flex absolute top-1/2 left-1/2`}
        style={{
          'margin-left': `-${Math.ceil(merged.height! / 2)}px`,
          'margin-top': `-${Math.ceil(merged.height! / 2)}px`
        }}
      >
        <Icon
          {...merged}
          class={twMerge(`text-fg-disabled`, merged.class)}
        />
      </span>
    </div>
  );
}
