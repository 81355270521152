import { JSXElement, mergeProps } from 'solid-js';
import { OrganizationData } from '~/api';
import { ActionsDropdown } from '~/components/ActionsDropdown';
import { getLocalizedString } from '~/i18n/utils.ts';
import { Localized } from '~/i18n';

type OrganizationListItemActionsProps = {
  org: OrganizationData;
  onDeleteOrg: (orgId: string) => void;
  onEditOrg: (org: OrganizationData) => void;
  disabled?: boolean;
};

export default function OrganizationListItemActions(
  props: OrganizationListItemActionsProps
): JSXElement {
  const merged = mergeProps(
    {
      disabled: false
    },
    props
  );
  return (
    <span
      class={`inline-block`}
      onClick={(e) => {
        // Done to ensure that the click on the
        // menu icon and the menu options
        // does not trigger a navigation
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <ActionsDropdown
        id={`${merged.org.id}-action`}
        disabled={merged.disabled}
        items={[
          {
            label: getLocalizedString(
              'pages.organizations.action_edit_organization'
            ),
            onClick: () => merged.onEditOrg(merged.org)
          },
          {
            label: (
              <span class={`text-error`}>
                <Localized
                  translationKey={
                    'pages.organizations.action_delete_organization'
                  }
                />
              </span>
            ),
            onClick: () => merged.onDeleteOrg(merged.org.id!)
          }
        ]}
      />
    </span>
  );
}
