import { JSXElement, mergeProps } from 'solid-js';
import Request from '~/api/Request.ts';
import {
  getSlug,
  PublicPagePath,
  shouldIgnoreUnauthorizedLoginRedirection
} from '~/components/AppRouter/utils.ts';

type WorkspaceApiConnectorProps = {
  children: JSXElement;
};

export function WorkspaceApiConnector(
  props: WorkspaceApiConnectorProps
): JSXElement {
  const merged = mergeProps({}, props);
  
  new Request({
    onResponseError: (error) => {
      // Handle unauthorized responses and send to /login
      if (
        error.response &&
        error.response.status === 401 &&
        !shouldIgnoreUnauthorizedLoginRedirection()
      ) {
        const urlParams = new URLSearchParams({
          redirectTo: location.pathname
        });
        window.location.assign(
          `${getSlug.publicPage(PublicPagePath.LOGIN, true)}?${urlParams.toString()}`
        );
      }
    }
  });
  return <>{merged.children}</>;
}
