import {
  createContext,
  JSXElement,
  mergeProps,
  Show,
  useContext
} from 'solid-js';
import {
  createToaster,
  CreateToasterProps,
  CreateToasterReturn
} from '@ark-ui/solid';
import * as Toast from '~/components/ui/Toast/Component.tsx';
import { AlertTriangleIcon, CheckCircleIcon, XIcon } from 'lucide-solid';
import { getAppliedClasses } from '~/utils/commonUtils.ts';
import { getLocalizedString } from '~/i18n/utils.ts';

const ToastContext = createContext();

type ToastContextProviderProps = {
  children: JSXElement;
};

type CreateToasterReturnType = ReturnType<
  (props: CreateToasterProps) => CreateToasterReturn
>;
type ToastAccessorType = CreateToasterReturnType extends [any, infer R]
  ? R
  : never;

export type ToastContextValue = {
  toast: ToastAccessorType;
};

type CreateToasterParams = {
  defaultSuccessTitle: JSXElement;
  defaultErrorTitle: JSXElement;
};
function getToaster(params: CreateToasterParams) {
  return createToaster({
    placement: 'top-end',
    duration: 3000,
    render(toast) {
      const ToastIcon: any =
        toast().type === 'success'
          ? CheckCircleIcon
          : toast().type === 'error'
            ? AlertTriangleIcon
            : undefined;

      return (
        <Toast.Root>
          <Toast.Title
            class={getAppliedClasses({
              success: toast().type === 'success',
              error: toast().type === 'error'
            })}
          >
            <span class={`flex items-center`}>
              <Show when={ToastIcon}>
                <ToastIcon
                  width={16}
                  class={`mr-1`}
                />
              </Show>
              <Show
                when={!toast().title}
                fallback={toast().title}
              >
                <Show when={toast().type === 'success'}>
                  {params.defaultSuccessTitle}
                </Show>
                <Show when={toast().type === 'error'}>
                  {params.defaultErrorTitle}
                </Show>
              </Show>
            </span>
          </Toast.Title>

          <Toast.Description>{toast().description}</Toast.Description>
          <Toast.CloseTrigger asChild>
            <button class={`text-fg-disabled hover:text-fg-subtle`}>
              <XIcon width={14} />
            </button>
          </Toast.CloseTrigger>
        </Toast.Root>
      );
    }
  });
}

export function ToastContextProvider(
  props: ToastContextProviderProps
): JSXElement {
  const merged = mergeProps({}, props);
  try {
    // Try block in case this is not wrapped in IntlProvider
    const [Toaster, toast] = getToaster({
      defaultSuccessTitle: getLocalizedString('toast.default_title_success'),
      defaultErrorTitle: getLocalizedString('toast.default_title_failure')
    });
    return (
      <ToastContext.Provider
        value={{
          toast: toast
        }}
      >
        <Toaster data-a={'test'} />
        {merged.children}
      </ToastContext.Provider>
    );
  } catch (e) {
    return <>{merged.children}</>;
  }
}

export const useToast = () => useContext(ToastContext) as ToastContextValue;
