import { JSXElement, Match, mergeProps, Show, Switch } from 'solid-js';
import { Link, MetaProvider, Title } from '@solidjs/meta';
import { globalStore } from '~/stores';
import { AppContexts } from '~/utils/commonUtils.ts';

type AppMetaProps = {
  children: JSXElement;
};

export default function AppMeta(props: AppMetaProps): JSXElement {
  const merged = mergeProps({}, props);

  return (
    <MetaProvider>
      <Switch>
        <Match when={globalStore.appContext === AppContexts.SCALEKIT_DASHBOARD}>
          <Title>Scalekit | Enterprise Ready Authentication Platform</Title>
        </Match>
        <Match when={globalStore.appContext === AppContexts.CUSTOMER_PORTAL}>
          <Title>SSO Configuration Wizard</Title>
        </Match>
      </Switch>
      <Show when={globalStore.appFavIcon}>
        <Link
          rel="icon"
          type="image/svg+xml"
          href={globalStore.appFavIcon!}
        />
      </Show>
      {merged.children}
    </MetaProvider>
  );
}
