import { JSXElement, mergeProps, Show } from 'solid-js';
import Tab from '~/components/ui/Tab';
import { useNavigate, useParams } from '@solidjs/router';
import { getConnectionListUrl } from '~/pages/Authentication/Connections/utils.ts';
import { Localized } from '~/i18n';
import { getTranslationKey } from '~/pages/AdminPortal/utils.ts';
import { OrgDetailsDataType } from '~/components/AppRouter/utils.ts';
import { getDirectoryListUrl } from '~/pages/DirectorySync/utils.ts';
import { AppErrors } from '~/components/ErrorBoundaries/utils.ts';
import { withPortalAuthToken } from '~/utils/commonUtils.ts';
import withOrganizationContext from '~/pages/AdminPortal/AdminPortalWithOrganizationContext.tsx';

type AdminPortalTabListProps = {
  children: JSXElement;
};

export default function AdminPortalTabList(
  props: AdminPortalTabListProps
): JSXElement {
  const merged = mergeProps({}, props);
  const params = useParams();
  const navigate = useNavigate();

  const getTabUrl = (tabType: OrgDetailsDataType): string => {
    if (tabType === OrgDetailsDataType.DIRECTORIES) {
      return getDirectoryListUrl({ params });
    } else if (tabType === OrgDetailsDataType.SSO_CONNECTIONS) {
      return getConnectionListUrl({ params });
    }
    throw new Error(AppErrors.INVALID_PORTAL_TYPE_IN_URL);
  };

  const portalFeatures: OrgDetailsDataType[] =
    import.meta.env.VITE_PORTAL_FEATURES.split(',');

  const SSOTabComponent = withOrganizationContext(
    () => <>{merged.children}</>,
    OrgDetailsDataType.SSO_CONNECTIONS
  );

  const DirSyncTabComponent = withOrganizationContext(
    () => <>{merged.children}</>,
    OrgDetailsDataType.DIRECTORIES
  );

  return (
    <>
      <Show when={portalFeatures.length > 1}>
        <Tab
          lazyMount={true}
          list={[
            {
              id: OrgDetailsDataType.SSO_CONNECTIONS,
              label: (
                <span class={`text-sm`}>
                  <Localized
                    translationKey={getTranslationKey('tab_titles.sso')}
                  />
                </span>
              ),
              content: SSOTabComponent
            },
            {
              id: OrgDetailsDataType.DIRECTORIES,
              label: (
                <span class={`text-sm`}>
                  <Localized
                    translationKey={getTranslationKey('tab_titles.dir_sync')}
                  />
                </span>
              ),
              content: DirSyncTabComponent
            }
          ]}
          value={params.portalType}
          onValueChange={({ value }) => {
            // required for the routeful tabs
            navigate(
              withPortalAuthToken(getTabUrl(value as OrgDetailsDataType))
            );
          }}
        />
      </Show>
      <Show
        when={
          portalFeatures.length === 1 &&
          portalFeatures[0] === OrgDetailsDataType.SSO_CONNECTIONS
        }
      >
        <SSOTabComponent />
      </Show>

      <Show
        when={
          portalFeatures.length === 1 &&
          portalFeatures[0] === OrgDetailsDataType.DIRECTORIES
        }
      >
        <DirSyncTabComponent />
      </Show>
    </>
  );
}
