import { ApiErrorInstance } from '~/api';

export type ServerErrorType = {
  message: string;
  error: Error | ApiErrorInstance | null;
};

const hasFieldError = (errorObj: any): boolean => {
  let hasError = false;
  for (let key in errorObj) {
    let hasBranchError = false;
    if (Array.isArray(errorObj[key]) && errorObj[key].length) {
      return true;
    } else if (errorObj[key] !== null && typeof errorObj[key] === 'object') {
      hasBranchError = hasBranchError || hasFieldError(errorObj[key]);
    }
    if (hasBranchError) {
      return true;
    }
  }
  return hasError;
};

export { hasFieldError };
