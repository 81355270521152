import {
  createContext,
  createEffect,
  JSXElement,
  mergeProps,
  onCleanup,
  useContext
} from 'solid-js';
import { createStore } from 'solid-js/store';
import { ConnectionData } from '~/api/types/connectionData.ts';
import {
  getConnectionList,
  getDirectoriesForOrganization,
  getDirectoryDetailsById,
  getOrganizationDirectoriesListQueryKey,
  getOrganizationsConnectionDetailsKey,
  getOrganizationsConnectionKey,
  getOrganizationsDirectoryDetailsQueryKey,
  getSSOConnectionDetails,
  isApiError,
  OrganizationData,
  retryAuthorizedRequests
} from '~/api';
import { useNavigate, useParams } from '@solidjs/router';
import { createQuery, useQueryClient } from '@tanstack/solid-query';
import { cloneObject, withPortalAuthToken } from '~/utils/commonUtils.ts';
import { HttpStatusCode } from 'axios';
import { getConnectionListUrl } from '~/pages/Authentication/Connections/utils.ts';
import { AppErrors } from '~/components/ErrorBoundaries/utils.ts';
import { DirectoryData } from '~/api/types/directoryData.ts';
import { OrgDetailsDataType } from '~/components/AppRouter/utils.ts';
import logger from '~/utils/logger';
import { timeSignatures } from '~/consts.ts';

type OrganizationContextProps = {
  children: (...params: any[]) => JSXElement;
  type?: OrgDetailsDataType;
};

export type OrganizationContextValueType = {
  organization: OrganizationData | null;
  connections: ConnectionData[];
  selectedConnection: ConnectionData | null;
  setSelectedConnection: (c: ConnectionData) => void;
  directories: DirectoryData[];
  selectedDirectory: DirectoryData | null;
  refetchConnectionDetails: () => Promise<any>;
  refetchDirectoryDetails: () => Promise<any>;
  addConnectionToList: (c: ConnectionData) => void;
  removeConnectionFromList: (cId: string) => void;
  setConnectionDetailsLoaded: (boolean) => void;
  pollDirectoryDetails: (interval?: number) => void;
  stopPollingDirectoryDetails: () => void;
};

const OrganizationContext = createContext();

const DEFAULT_POLL_INTERVAL = 10 * timeSignatures.SEC_IN_MILLIS;

export default function OrganizationContextProvider(
  props: OrganizationContextProps
): JSXElement {
  const merged = mergeProps(
    {
      type: OrgDetailsDataType.SSO_CONNECTIONS
    },
    props
  );

  const params = useParams();
  const navigate = useNavigate();
  const [isDataLoaded, setIsDataLoaded] = createStore({
    connectionsQuery: merged.type !== OrgDetailsDataType.SSO_CONNECTIONS,
    connectionDetailsQuery: merged.type !== OrgDetailsDataType.SSO_CONNECTIONS,
    directoriesQuery: merged.type !== OrgDetailsDataType.DIRECTORIES,
    directoriesDetailsQuery: merged.type !== OrgDetailsDataType.DIRECTORIES
  });

  const queryClient = useQueryClient();

  const organizationId = params.organizationId;

  const timers: {
    directory: ReturnType<typeof setTimeout> | undefined;
    connection: ReturnType<typeof setTimeout> | undefined;
  } = {
    directory: undefined,
    connection: undefined
  };

  const [contextData, setContextData] =
    createStore<OrganizationContextValueType>({
      organization: {
        id: params.organizationId,
        displayName: ''
      },
      connections: [],
      selectedConnection: null,
      setSelectedConnection: (connection) =>
        setContextData('selectedConnection', connection),
      directories: [],
      selectedDirectory: null,
      refetchConnectionDetails: async () =>
        await connectionDetailsQuery.refetch(),
      refetchDirectoryDetails: async () => directoryDetailsQuery.refetch(),
      addConnectionToList: (connection) => {
        queryClient.setQueryData(connectionsQueryKey, (cachedData) => {
          const connections = [...(cachedData as ConnectionData[]), connection];
          setContextData('connections', connections);
          return connections;
        });
      },
      removeConnectionFromList: (connectionId) => {
        queryClient.setQueryData(connectionsQueryKey, (cachedData) => {
          const connections = [
            ...(cachedData as ConnectionData[]).filter(
              ({ id }) => id !== connectionId
            )
          ];
          setContextData('connections', connections);
          return connections;
        });
      },
      setConnectionDetailsLoaded: (isLoaded) =>
        setIsDataLoaded('connectionDetailsQuery', isLoaded),
      pollDirectoryDetails: (pollInterval: number = DEFAULT_POLL_INTERVAL) => {
        if (timers.directory) {
          contextData.stopPollingDirectoryDetails();
        }
        timers.directory = setInterval(async () => {
          try {
            // ideally we should have called refetchDirectoryDetails in the setInterval
            // but that would trigger a few more side effects
            // to avoid that we are calling the api method directly here
            const directoryDetails = await getDirectoryDetailsById(
              organizationId,
              params.directoryId
            );
            setContextData('selectedDirectory', {
              ...contextData.selectedDirectory,
              ...directoryDetails
            });
          } catch (e) {
            // silently log the error
            logger.error(
              `Failed to poll directory details directoryId :: ${params.directoryId}`
            );
          }
        }, pollInterval);
      },
      stopPollingDirectoryDetails: () => {
        timers.directory && clearInterval(timers.directory);
      }
    });

  const connectionsQueryKey = getOrganizationsConnectionKey(
    params.organizationId
  );

  const connectionsQuery = createQuery(() => ({
    queryKey: connectionsQueryKey,
    queryFn: async () => {
      return await getConnectionList(params.organizationId);
    },
    retry: retryAuthorizedRequests,
    enabled: false
  }));

  createEffect(() => {
    if (connectionsQuery.isSuccess) {
      setIsDataLoaded('connectionsQuery', true);
      setContextData(
        'connections',
        connectionsQuery.data as Array<ConnectionData>
      );
    } else if (
      connectionsQuery.isError &&
      isApiError(connectionsQuery.error) &&
      connectionsQuery.error.httpStatus === HttpStatusCode.Unauthorized
    ) {
      throw new Error(AppErrors.CUSTOMER_PORTAL_LINK_EXPIRED);
    }
  });

  const connectionDetailsQuery = createQuery(() => ({
    queryKey: getOrganizationsConnectionDetailsKey(
      params.organizationId,
      params.connectionId
    ),
    queryFn: async () => {
      if (params.connectionId) {
        return await getSSOConnectionDetails(
          organizationId,
          params.connectionId
        );
      }
      return null;
    },
    retry: retryAuthorizedRequests,
    enabled: false
  }));

  createEffect(async () => {
    if (
      params.connectionId &&
      (!contextData.selectedConnection ||
        params.connectionId !== contextData.selectedConnection?.id)
    ) {
      setIsDataLoaded('connectionDetailsQuery', false);
      await connectionDetailsQuery.refetch();
    }
  });

  createEffect(() => {
    if (!connectionDetailsQuery.isPending && connectionDetailsQuery.isSuccess) {
      setIsDataLoaded('connectionDetailsQuery', true);
      // @todo revisit upadting state with the new connection details
      // it was assigned with null to prevent merging of the state
      // there should be a better way to do this
      setContextData('selectedConnection', null);
      setContextData(
        'selectedConnection',
        cloneObject(connectionDetailsQuery.data)
      );
    } else if (
      !connectionDetailsQuery.isPending &&
      connectionDetailsQuery.isError
    ) {
      if (
        isApiError(connectionDetailsQuery.error) &&
        connectionDetailsQuery.error.httpStatus === HttpStatusCode.NotFound
      ) {
        navigate(withPortalAuthToken(getConnectionListUrl({ params })));
        return;
      }
      throw connectionDetailsQuery.error;
    }
  });

  const directoriesQueryKey = getOrganizationDirectoriesListQueryKey(
    params.organizationId
  );

  const directoriesQuery = createQuery(() => ({
    queryKey: directoriesQueryKey,
    queryFn: async () => {
      return await getDirectoriesForOrganization(params.organizationId);
    },
    retry: retryAuthorizedRequests,
    enabled: false
  }));

  createEffect(() => {
    if (directoriesQuery.isSuccess) {
      setIsDataLoaded('directoriesQuery', true);
      setContextData(
        'directories',
        directoriesQuery.data as Array<DirectoryData>
      );
    } else if (
      directoriesQuery.isError &&
      isApiError(directoriesQuery.error) &&
      directoriesQuery.error.httpStatus === HttpStatusCode.Unauthorized
    ) {
      throw new Error(AppErrors.CUSTOMER_PORTAL_LINK_EXPIRED);
    }
  });

  const directoryDetailsQuery = createQuery(() => ({
    queryKey: getOrganizationsDirectoryDetailsQueryKey(
      params.organizationId,
      params.directoryId
    ),
    queryFn: async () => {
      if (params.directoryId) {
        return await getDirectoryDetailsById(
          organizationId,
          params.directoryId
        );
      }
      return null;
    },
    retry: retryAuthorizedRequests,
    enabled: false
  }));

  createEffect(async () => {
    if (
      params.directoryId &&
      (!contextData.selectedDirectory ||
        params.directoryId !== contextData.selectedDirectory?.id)
    ) {
      setIsDataLoaded('directoriesDetailsQuery', false);
      await directoryDetailsQuery.refetch();
    }
  });

  createEffect(() => {
    if (!directoryDetailsQuery.isPending && directoryDetailsQuery.isSuccess) {
      setIsDataLoaded('directoriesDetailsQuery', true);
      // @todo revisit upadting state with the new directory details
      // it was assigned with null to prevent merging of the state
      // there should be a better way to do this
      setContextData('selectedDirectory', null);
      setContextData(
        'selectedDirectory',
        cloneObject(directoryDetailsQuery.data)
      );
    } else if (
      !directoryDetailsQuery.isPending &&
      directoryDetailsQuery.isError
    ) {
      if (
        isApiError(directoryDetailsQuery.error) &&
        directoryDetailsQuery.error.httpStatus === HttpStatusCode.NotFound
      ) {
        // @todo fix url
        // navigate(withPortalAuthToken(getConnectionListUrl({ params })));
        return;
      }
      throw directoryDetailsQuery.error;
    }
  });

  createEffect(() => {
    if (merged.type === OrgDetailsDataType.SSO_CONNECTIONS) {
      connectionsQuery.refetch();
      connectionDetailsQuery.refetch();
    } else if (merged.type === OrgDetailsDataType.DIRECTORIES) {
      directoriesQuery.refetch();
      directoryDetailsQuery.refetch();
    }
  });

  const isAllDataLoaded = () => {
    return (
      isDataLoaded.connectionsQuery &&
      isDataLoaded.connectionDetailsQuery &&
      isDataLoaded.directoriesQuery &&
      isDataLoaded.directoriesDetailsQuery
    );
  };

  onCleanup(() => {
    contextData.stopPollingDirectoryDetails();
  });

  return (
    <OrganizationContext.Provider value={contextData}>
      {merged.children(isAllDataLoaded())}
    </OrganizationContext.Provider>
  );
}

export const useOrganizationContext = (): OrganizationContextValueType =>
  useContext(OrganizationContext) as OrganizationContextValueType;
