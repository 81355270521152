import {
  FeatureFlagKeys,
  FeatureFlagData,
  FeatureFlagMap
} from '~/api/types/featureFlagData.ts';

const transformFeatureFlagResponse: (
  flags: FeatureFlagData[] | null
) => FeatureFlagMap = (flags) => {
  return !flags
    ? null
    : (flags.reduce((acc, flag) => {
        return {
          ...acc,
          [flag.key as FeatureFlagKeys]: flag
        };
      }, {}) as FeatureFlagMap);
};

export { transformFeatureFlagResponse };
