import { createSignal, JSXElement, mergeProps, Show } from 'solid-js';
import { twMerge } from 'tailwind-merge';
import styles from './layout.module.scss';

type TwoColumnDraggableContainerProps = {
  leftContent: JSXElement;
  rightContent: JSXElement;
  class?: string;
};

export default function TwoColumnDraggableContainer(
  props: TwoColumnDraggableContainerProps
): JSXElement {
  const merged = mergeProps(
    {
      class: ''
    },
    props
  );
  const [isDragging, setIsDragging] = createSignal(false);

  let leftContainerRef: HTMLDivElement | undefined;
  let rightContainerRef: HTMLDivElement | undefined;

  const dragHandlers = {
    onMouseDown: (e: MouseEvent) => {
      e.preventDefault();
      setIsDragging(true);
    },
    onMouseUp: (e: MouseEvent) => {
      e.preventDefault();
      setIsDragging(false);
    },
    onMouseMove: (e: MouseEvent) => {
      if (!isDragging()) {
        return;
      }
      if (!leftContainerRef || !rightContainerRef) {
        return;
      }
      const offsetLeft = rightContainerRef.offsetLeft;
      // console.log(e.clientX, offsetLeft);
      const pointerRelativeXpos = e.clientX - offsetLeft;
      // console.log(pointerRelativeXpos);

      // Arbitrary minimum width set on box A, otherwise its inner content will collapse to width of 0
      const rightBoxMinWidth = 400;
      const rightBoxCurrentWidth = rightContainerRef.offsetWidth;

      const righBoxWidth = Math.max(
        rightBoxMinWidth,
        rightBoxCurrentWidth - pointerRelativeXpos
      );

      rightContainerRef.style.width = `${righBoxWidth}px`;
    }
  };
  return (
    <div
      class={twMerge(styles.twoColumnDraggableContainer, merged.class)}
      onMouseUp={dragHandlers.onMouseUp}
      onMouseMove={dragHandlers.onMouseMove}
    >
      <Show when={isDragging()}>
        <div class={styles.onDragOverlay}></div>
      </Show>
      <div
        ref={leftContainerRef}
        class={styles.resizeableLeftContainer}
      >
        {merged.leftContent}
      </div>
      <Show when={merged.rightContent}>
        <div
          ref={rightContainerRef}
          class={styles.resizeableRightContainer}
        >
          <div
            class={styles.resizeableDragger}
            onMouseDown={dragHandlers.onMouseDown}
          ></div>
          {merged.rightContent}
        </div>
      </Show>
    </div>
  );
}
