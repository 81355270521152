import { JSXElement, mergeProps, Show } from 'solid-js';
import { SSOConfigurationHelpButton } from './SSOConfigurationHelpButton.tsx';
import { CustomerPortalPageTypes } from '~/components/Layout/utils.tsx';
import { usePortalCustomizationContext } from '~/portal-customization/PortalCustomizations.tsx';
import styles from './header.module.scss';
import { Localized } from '~/i18n';
import Alert from '~/components/ui/Alert';
import { OrgDetailsDataType } from '~/components/AppRouter/utils.ts';
import { useNeedHelpContext } from '~/components/Layout/NeedHelp/NeedHelpContext.tsx';

type CustomerPortalHeaderProps = {
  pageType?: CustomerPortalPageTypes;
  isHelpDisplayed: boolean;
  onClickNeedHelp: () => void;
};

const getTranslationKey = (key) => `admin_portals.header.${key}`;

const getPortalFeatures = (features: string[]) => {
  return features
    .map((feature: string) => {
      switch (feature) {
        case OrgDetailsDataType.SSO_CONNECTIONS:
          return 'Single Sign-on';
        case OrgDetailsDataType.DIRECTORIES:
          return 'Directory Sync';
      }
    })
    .join(' and ');
};

export function CustomerPortalHeader(
  props: CustomerPortalHeaderProps
): JSXElement {
  const merged = mergeProps({}, props);
  const needHelpContext = useNeedHelpContext();

  const customizationContext = usePortalCustomizationContext();

  return (
    <div>
      <div class={styles.customerPortalHeader}>
        <div class={`flex items-center`}>
          <Show when={customizationContext.brandConfig?.logoUrl}>
            <div class={`h-[36px] pr-4 mr-2 border-r border-l-border-default`}>
              <img
                class={`max-h-full`}
                src={`${customizationContext.brandConfig!.logoUrl}`}
                alt={'logo'}
              />
            </div>
          </Show>
          <div class={`font-semibold py-1.5`}>
            <Localized
              translationKey={getTranslationKey('header_title_admin_portal')}
            />
          </div>
        </div>

        <Show
          when={
            merged.pageType === CustomerPortalPageTypes.SSO_CONFIGURATION &&
            needHelpContext.shouldShowHelp
          }
        >
          <SSOConfigurationHelpButton
            isHelpDisplayed={merged.isHelpDisplayed}
            onClickNeedHelp={merged.onClickNeedHelp}
          />
        </Show>
      </div>
      <div class={`font-normal text-sm text-fg-muted`}>
        <Alert variant={'info'}>
          <Localized
            translationKey={getTranslationKey('welcome_note')}
            vars={{
              features: getPortalFeatures(
                import.meta.env.VITE_PORTAL_FEATURES.split(',')
              )
            }}
          />
        </Alert>
      </div>
    </div>
  );
}
