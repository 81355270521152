import { Component, For, JSXElement, mergeProps, Show } from 'solid-js';
import LeftNavItem from '~/components/Layout/LeftNav/LeftNavItem.tsx';
import { LeftNavGroupConfig } from '~/components/Layout/LeftNav/utils.tsx';

type LeftNavGroupProps = {
  groups: Array<LeftNavGroupConfig>;
};

export default function LeftNavGroup(props: LeftNavGroupProps): JSXElement {
  const merged = mergeProps({}, props);
  return (
    <>
      <For each={merged.groups}>
        {(group) => {
          return (
            <>
              <Show when={group.labelComponent}>
                {(function () {
                  const GroupLabelComponent: Component = group.labelComponent!;
                  return (
                    <div class={'px-2 mb-2 text-fg-subtle text-xs'}>
                      <GroupLabelComponent />
                    </div>
                  );
                })()}
              </Show>

              <ul class={`list-none mb-5`}>
                <For each={group.items.filter((item) => !item.hidden)}>
                  {(item) => (
                    <LeftNavItem
                      iconComponent={item.iconComponent}
                      labelComponent={item.labelComponent}
                      href={item.href}
                    />
                  )}
                </For>
              </ul>
            </>
          );
        }}
      </For>
    </>
  );
}
