import { JSXElement, mergeProps } from 'solid-js';

type CustomerPortalSSOBaseProps = {
  children: JSXElement;
};

export default function AdminPortalBase(
  props: CustomerPortalSSOBaseProps
): JSXElement {
  const merged = mergeProps({}, props);

  return (
    <div class={`w-full min-h-screen bg-bg-disabled`}>{merged.children}</div>
  );
}
