import { JSXElement, mergeProps } from 'solid-js';
import { logoutMember } from '~/api';
import { getSlug, PublicPagePath } from '~/components/AppRouter/utils.ts';
import { useToast } from '~/components/ui/Toast';
import { useIntl } from '@cookbook/solid-intl';
import { getLocalizedString } from '~/i18n/utils.ts';
import { logErrorToSentry } from '~/utils/productLogging/sentry.ts';
import { resetIdentity } from '~/utils/productLogging/posthog.ts';
import Link from '~/components/Link';
import { twMerge } from 'tailwind-merge';

type LogoutButtonProps = {
  children: JSXElement;
  class?: string;
};
export default function LogoutButton(props: LogoutButtonProps): JSXElement {
  const merged = mergeProps(
    {
      class: ''
    },
    props
  );
  const toaster = useToast();
  const intl = useIntl();

  return (
    <Link
      href={'#'}
      class={twMerge(
        `w-full flex justify-start items-center font-normal mr-4`,
        merged.class
      )}
      onClick={async () => {
        try {
          await logoutMember();
          resetIdentity();
          window.location.assign(
            getSlug.publicPage(PublicPagePath.LOGIN, true)
          );
        } catch (e) {
          logErrorToSentry(e as Error);
          toaster.toast().error({
            description: getLocalizedString('server_errors.logout_failed', {
              intl
            })
          });
        }
      }}
    >
      {merged.children}
    </Link>
  );
}
