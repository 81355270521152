import { createQuery } from '@tanstack/solid-query';
import {
  createContext,
  createEffect,
  JSXElement,
  mergeProps,
  Show,
  untrack,
  useContext
} from 'solid-js';
import {
  getCurrentPortalCustomizationConfig,
  getPortalCustomizationConfigQuery,
  retryAuthorizedRequests
} from '~/api';
import './portal-customisation-base.scss';
import SkLoader from '../components/ui/SkLoader';
import { PortalCustomizationSettings } from '~/api/types/customisationData.ts';
import { createStore } from 'solid-js/store';
import { HttpStatusCode } from 'axios';
import logger from '~/utils/logger';
import { applyBrandCustomization } from '~/portal-customization/utils.ts';
import { logErrorToSentry } from '~/utils/productLogging/sentry.ts';
import { AppErrors } from '~/components/ErrorBoundaries/utils.ts';
import { setGlobalStore } from '~/stores';

type PortalCustomizationContextVal = {
  brandConfig: PortalCustomizationSettings | null;
};

const PortalCustomizationContext =
  createContext<PortalCustomizationContextVal>();

type PortalCustomizationProps = {
  children: JSXElement;
};

export default function PortalCustomizations(
  props: PortalCustomizationProps
): JSXElement {
  const merged = mergeProps({}, props);

  const [contextData, setContextData] =
    createStore<PortalCustomizationContextVal>({
      brandConfig: null
    });

  const portalCustomizationQuery = createQuery(() => ({
    queryKey: getPortalCustomizationConfigQuery(),
    queryFn: async () => await getCurrentPortalCustomizationConfig(),
    retry: retryAuthorizedRequests
  }));

  createEffect(() => {
    if (portalCustomizationQuery.isSuccess) {
      if (!portalCustomizationQuery.data) {
        logErrorToSentry(
          new Error(AppErrors.INVALID_PORTAL_CUSTOMISATION_CONFIG)
        );
        return;
      }
      logger.info(
        `Portal customization settings found`,
        portalCustomizationQuery.data
      );
      // apply customization before updating context
      applyBrandCustomization(
        portalCustomizationQuery.data as PortalCustomizationSettings
      );
      untrack(() => {
        setContextData('brandConfig', portalCustomizationQuery.data);
        if (contextData.brandConfig?.iconUrl) {
          setGlobalStore('appFavIcon', contextData.brandConfig?.iconUrl!);
        }
      });
    } else if (
      portalCustomizationQuery.isError &&
      portalCustomizationQuery.error.httpStatus !== HttpStatusCode.NotFound
    ) {
      throw portalCustomizationQuery.error;
    }
  });

  return (
    <PortalCustomizationContext.Provider value={contextData}>
      <Show
        when={!portalCustomizationQuery.isPending}
        fallback={<SkLoader />}
      >
        {merged.children}
      </Show>
    </PortalCustomizationContext.Provider>
  );
}

export const usePortalCustomizationContext =
  (): PortalCustomizationContextVal =>
    useContext(PortalCustomizationContext) as PortalCustomizationContextVal;
