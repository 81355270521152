import { JSXElement, mergeProps } from 'solid-js';
import Base from '../Base.tsx';
import { ChevronsUpDownIcon, SettingsIcon, UserPlusIcon } from 'lucide-solid';
import Menu from '~/components/ui/Menu';
import { Localized } from '~/i18n';
import styles from '../switcher.module.scss';
import WorkspaceSwitcherMenuItem from '~/components/Layout/Header/SwitcherButtons/WorkspaceSwitcher/WorkspaceSwitcherMenuItem.tsx';
import { getLocalizedString } from '~/i18n/utils.ts';
import { getSlug, WorkspacePagePath } from '~/components/AppRouter/utils.ts';

type WorkspaceSwitcherProps = {
  children: JSXElement;
};

export default function WorkspaceSwitcher(
  props: WorkspaceSwitcherProps
): JSXElement {
  const merged = mergeProps({}, props);
  return (
    <Menu
      id={`workspace-switcher`}
      groups={[
        {
          label: (
            <div class={styles.menuCategoryText}>
              <Localized translationKey={`top_nav_group_labels.workspace`} />
            </div>
          ),
          items: [
            {
              label: (
                <WorkspaceSwitcherMenuItem
                  iconComponent={SettingsIcon}
                  label={getLocalizedString(`page_titles.workspace_general`)}
                  href={getSlug.workspace(
                    WorkspacePagePath.WORKSPACE_GENERAL,
                    true
                  )}
                />
              )
            },
            {
              label: (
                <WorkspaceSwitcherMenuItem
                  iconComponent={UserPlusIcon}
                  label={getLocalizedString(`page_titles.members`)}
                  href={getSlug.workspace(
                    WorkspacePagePath.WORKSPACE_MEMBERS,
                    true
                  )}
                />
              )
            }
          ]
        }
      ]}
    >
      <Base>
        <span class={`mr-1`}>{merged.children}</span>
        <ChevronsUpDownIcon />
      </Base>
    </Menu>
  );
}
