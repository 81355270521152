import { JSXElement, mergeProps, Show } from 'solid-js';
import { getLocalizedString } from '~/i18n/utils.ts';

type LocalizedProps = {
  translationKey: string;
  upperCase?: boolean;
  vars?: Record<string, any>;
};
export function Localized(props: LocalizedProps): JSXElement {
  const merged = mergeProps({ upperCase: false }, props);

  return (
    <>
      <Show
        when={merged.upperCase}
        fallback={getLocalizedString(props.translationKey, merged.vars)}
      >
        {getLocalizedString(props.translationKey, merged.vars).toUpperCase()}
      </Show>
    </>
  );
}
