import { createContext, JSXElement, mergeProps, useContext } from 'solid-js';
import { createStore } from 'solid-js/store';
import ConfirmModal, {
  ConfirmModalPrimaryActionVariants
} from './ConfirmModal.tsx';
import { useIntl } from '@cookbook/solid-intl';

import { getLocalizedString } from '~/i18n/utils.ts';

const ConfirmModalContext = createContext();

export type ConfirmModalValues = {
  setIsLoading: (isLoading: boolean) => void;
  show: (params: ShowConfirmModalParams) => void;
  close: () => void;
};

type ConfirmModalProviderProps = {
  children: JSXElement;
};

type ShowConfirmModalParams = {
  title: JSXElement;
  primaryActionVariant?: ConfirmModalPrimaryActionVariants;
  description: JSXElement;
  primaryActionLabel?: string;
  onConfirm: () => void;
  onCancel?: () => void;
};

type ModalPropsStoreType = {
  isLoading: boolean;
  isOpen: boolean;
  title: JSXElement;
  description: JSXElement;
  onConfirm: () => void;
  onCancel: () => void;
  primaryActionVariant: ConfirmModalPrimaryActionVariants;
  primaryActionLabel: JSXElement;
};

export default function ConfirmModalProvider(
  props: ConfirmModalProviderProps
): JSXElement {
  const merged = mergeProps({}, props);
  const intl = useIntl();

  const [modalProps, setModalProps] = createStore<ModalPropsStoreType>({
    isLoading: false,
    isOpen: false,
    title: '',
    description: '',
    onConfirm: () => {},
    onCancel: () => {},
    primaryActionVariant: 'danger',
    primaryActionLabel: ''
  });
  const [contextValues] = createStore<ConfirmModalValues>({
    show: (params: ShowConfirmModalParams) => {
      setModalProps('title', params.title);
      setModalProps('description', params.description);
      setModalProps(
        'primaryActionLabel',
        params.primaryActionLabel ||
          getLocalizedString('cta_labels.proceed', { intl })
      );
      setModalProps(
        'primaryActionVariant',
        params.primaryActionVariant || 'danger'
      );
      // if second param is a function, it will be treated as a setter.
      // so doing setModalProps('onConfirm', params.onConfirm) will invoke params.onConfirm
      // instead of assigning it. Hence, it is returned from the setter function
      setModalProps('onConfirm', () => params.onConfirm);
      if (params.onCancel) {
        // same as above
        setModalProps('onCancel', () => params.onCancel);
      }
      // Push the rendering to the end of the callstack
      setTimeout(() => setModalProps('isOpen', true));
    },
    close: () => {
      setModalProps('isOpen', false);
      // Push the rendering to the end of the callstack
      setTimeout(() => {
        setModalProps('isLoading', false);
      });
    },
    setIsLoading: (isLoading: boolean) => {
      setModalProps('isLoading', isLoading);
    }
  });

  return (
    <ConfirmModalContext.Provider value={contextValues}>
      <ConfirmModal
        title={modalProps.title}
        description={modalProps.description}
        open={modalProps.isOpen}
        onConfirm={modalProps.onConfirm}
        onCancel={modalProps.onCancel}
        onOpenChange={(isOpen: boolean) => setModalProps('isOpen', isOpen)}
        isLoading={modalProps.isLoading}
        primaryActionVariant={modalProps.primaryActionVariant}
        primaryActionLabel={modalProps.primaryActionLabel}
      >
        {merged.children}
      </ConfirmModal>
    </ConfirmModalContext.Provider>
  );
}

export const useConfirm = () =>
  useContext(ConfirmModalContext) as ConfirmModalValues;
