import { JSXElement } from 'solid-js';
import { useLocation, useNavigate } from '@solidjs/router';
import { ChevronLeftIcon } from 'lucide-solid';
import { Localized } from '~/i18n';
import Link from '~/components/Link';
import { getSlug } from '~/components/AppRouter/utils.ts';

export default function BackNavigation(): JSXElement {
  const navigate = useNavigate();
  const location = useLocation();

  const navigateBack = (e: Event) => {
    e.preventDefault();
    const { previous = null }: any = location.state || {};

    const state: any = {
      previous: previous || `${getSlug.workspaceBaseNamespace()}/`
    };
    navigate(state.previous);
  };
  return (
    <Link
      href={'#'}
      class={`flex mb-6 hover:text-fg-muted transition-colors delay-75`}
      onClick={navigateBack}
    >
      <ChevronLeftIcon
        width={20}
        class={`mr-1`}
      />
      <Localized translationKey={`cta_labels.back`} />
    </Link>
  );
}
