import { ErrorBoundary, JSXElement, mergeProps } from 'solid-js';
import ContactSupport from '~/components/ErrorBoundaries/ContactSupport.tsx';
import { logErrorToSentry } from '~/utils/productLogging/sentry.ts';

type WorkspaceErrorBoundaryProps = {
  children: JSXElement;
};

export default function WorkspaceErrorBoundary(
  props: WorkspaceErrorBoundaryProps
): JSXElement {
  const merged = mergeProps({}, props);
  return (
    <ErrorBoundary
      fallback={(err) => {
        console.error(err);
        logErrorToSentry(err);
        return <ContactSupport showLogo={false} />;
      }}
    >
      {merged.children}
    </ErrorBoundary>
  );
}
