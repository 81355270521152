import type { Component } from 'solid-js';
import { QueryClient, QueryClientProvider } from '@tanstack/solid-query';
import { SolidQueryDevtools } from '@tanstack/solid-query-devtools';
import AppRouter from '~/components/AppRouter';
import AppErrorBoundary from 'src/components/ErrorBoundaries';
import { initAppCommons } from '~/utils/commonUtils.ts';
import styles from './app.module.scss';
import { isApi5xxResponseStatus, isApiError } from '~/api';
import { LanguageProvider } from '~/i18n';
import AppMeta from '~/components/Layout/AppMeta.tsx';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: 1000 * 60 * 60 * 24, // 24 hours
      throwOnError: (error) => {
        // propagate all 5xx to the error boundary
        return isApiError(error) && isApi5xxResponseStatus(error.httpStatus);
      },
      refetchOnWindowFocus: false,
      staleTime: Infinity
    }
  }
});
const App: Component = () => {
  initAppCommons();

  return (
    <AppMeta>
      <div class={styles.appStyles}>
        <LanguageProvider>
          <AppErrorBoundary>
            <QueryClientProvider client={queryClient}>
              <AppRouter />
              <SolidQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
          </AppErrorBoundary>
        </LanguageProvider>
      </div>
    </AppMeta>
  );
};

export default App;
