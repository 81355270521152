import logger from '../logger';

function initialize() {
  const clarityTrackerCode = import.meta.env.VITE_MS_CLARITY_CODE;
  if (!clarityTrackerCode) {
    logger.info(
      'MS Clarity tracker code not found, skipping Clarity initialisation'
    );
    return;
  }
  (function (c, l, a, r, i, t, y) {
    c[a] =
      c[a] ||
      function () {
        (c[a].q = c[a].q || []).push(arguments);
      };
    t = l.createElement(r);
    t.async = 1;
    t.src = 'https://www.clarity.ms/tag/' + i;
    y = l.getElementsByTagName(r)[0];
    y.parentNode.insertBefore(t, y);
  })(window, document, 'clarity', 'script', clarityTrackerCode);
}

function getInitializer() {
  let isInitialized = false;
  return () => {
    if (isInitialized) {
      return;
    }
    initialize();
    isInitialized = true;
  };
}

const initClarity = getInitializer();

export { initClarity };
