import {
  createEffect,
  createSignal,
  JSXElement,
  mergeProps,
  Show
} from 'solid-js';
import { createQuery } from '@tanstack/solid-query';
import { miscConstants } from '~/consts.ts';
import { setGlobalStore } from '~/stores';
import {
  createPortalSession,
  getPortalSessionQuery,
  retryAuthorizedRequests
} from '~/api';
import SkLoader from '~/components/ui/SkLoader';
import { AppErrors } from '~/components/ErrorBoundaries/utils.ts';

type PortalAuthenticationProps = {
  children: JSXElement;
};

export default function PortalAuthentication(
  props: PortalAuthenticationProps
): JSXElement {
  const merged = mergeProps({}, props);
  const [isLoaded, setIsLoaded] = createSignal(false);

  const params = new URLSearchParams(new URL(window.location.href).search);
  if (params.has(miscConstants.QUERY_PARAMS.CUSTOMER_PORTAL_LINK_ID)) {
    const portalSessionQuery = createQuery(() => ({
      queryKey: getPortalSessionQuery(),
      queryFn: async () =>
        await createPortalSession(
          params.get(miscConstants.QUERY_PARAMS.CUSTOMER_PORTAL_LINK_ID) || ''
        ),
      retry: retryAuthorizedRequests
    }));

    createEffect(() => {
      if (portalSessionQuery.isSuccess) {
        setIsLoaded(true);
        portalSessionQuery.data.accessToken &&
          setGlobalStore(
            'portalAuthToken',
            portalSessionQuery.data.accessToken
          );
      } else if (portalSessionQuery.isError) {
        throw new Error(AppErrors.CUSTOMER_PORTAL_LINK_EXPIRED);
      }
    });
  } else if (
    params.has(miscConstants.QUERY_PARAMS.CUSTOMER_PORTAL_AUTH_TOKEN)
  ) {
    setGlobalStore(
      'portalAuthToken',
      params.get(miscConstants.QUERY_PARAMS.CUSTOMER_PORTAL_AUTH_TOKEN || '')
    );
    setGlobalStore('shouldForwardToken', true);
    setIsLoaded(true);
  } else {
    setIsLoaded(true);
  }

  return (
    <Show
      when={isLoaded()}
      fallback={<SkLoader />}
    >
      {merged.children}
    </Show>
  );
}
