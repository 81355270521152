import { createSignal, JSXElement, Show } from 'solid-js';
import SkLoader from '~/components/ui/SkLoader';
import { useNeedHelpContext } from '~/components/Layout/NeedHelp/NeedHelpContext.tsx';

export default function NeedHelpDocContent(): JSXElement {
  const needHelpContext = useNeedHelpContext();
  const [showLoader, setShowLoader] = createSignal(true);

  return (
    <div class={`w-full h-full`}>
      <iframe
        class={`w-full h-full`}
        src={needHelpContext.helpDocLink}
        onLoad={() => {
          setShowLoader(false);
        }}
      ></iframe>
      <Show when={showLoader()}>
        <SkLoader />
      </Show>
    </div>
  );
}
