import { JSXElement, mergeProps } from 'solid-js';
import Tooltip from '~/components/ui/Tooltip';
import { Info } from 'lucide-solid';
import { twMerge } from 'tailwind-merge';

type InfoIconProps = {
  tooltipContent: JSXElement;
  width?: number;
  class?: string;
};

export default function InfoIcon(props: InfoIconProps): JSXElement {
  const merged = mergeProps(
    {
      width: 16,
      class: ''
    },
    props
  );
  return (
    <Tooltip content={merged.tooltipContent}>
      <Info
        width={merged.width}
        class={twMerge(`cursor-pointer`, merged.class)}
      />
    </Tooltip>
  );
}
