import { JSXElement } from 'solid-js';
import styles from '~/components/Layout/layout.module.scss';
import WorkspaceErrorBoundary from '~/components/ErrorBoundaries/WorkspaceErrorBoundary.tsx';

type RightContentProps = {
  children: JSXElement;
};
export default function RightContent(props: RightContentProps) {
  return (
    <div class={styles.rightSectionContainer}>
      <WorkspaceErrorBoundary>{props.children}</WorkspaceErrorBoundary>
    </div>
  );
}
