import { Component, Show } from 'solid-js';
import { useAppData } from '~/components/AppData';
import {
  FeatureFlagKeys,
  FeatureFlagMap
} from '~/api/types/featureFlagData.ts';
import { Navigate } from '@solidjs/router';
import { getSlug } from '~/components/AppRouter/utils.ts';

function isFeatureEnabled(
  flags: FeatureFlagMap,
  feature: FeatureFlagKeys
): boolean {
  return !!flags?.[feature]?.value;
}

function withFeatureFlag<T extends object>(
  component: Component<T>,
  feature: FeatureFlagKeys
) {
  const Renderer = component;
  return (props: T) => {
    const appData = useAppData();

    return (
      <Show
        when={
          appData.featureFlags &&
          isFeatureEnabled(appData.featureFlags, feature)
        }
        fallback={<Navigate href={`${getSlug.workspaceBaseNamespace()}/`} />}
      >
        <Renderer {...props} />
      </Show>
    );
  };
}

export { isFeatureEnabled, withFeatureFlag };
