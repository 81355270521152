import * as Tooltip from './Component.tsx';
import { JSXElement, mergeProps, splitProps } from 'solid-js';
import { Portal } from 'solid-js/web';

type ComponentProps = {
  children: JSXElement;
  content: JSXElement;
  triggerClass?: string;
};
type TooltipImplProps = Tooltip.RootProps & ComponentProps;

const TooltipImpl = (props: TooltipImplProps) => {
  const merged = mergeProps(
    {
      positioning: {
        placement: 'right'
      },
      openDelay: 0,
      closeDelay: 0,
      lazyMount: false,
      interactive: false,
      closeOnPointerDown: false,
      closeOnEsc: false,
      triggerClass: ''
    },
    props
  );

  const splittedProps = splitProps(merged, [
    'children',
    'content',
    'triggerClass'
  ]);
  const componentProps: ComponentProps = splittedProps[0];
  const tooltipProps: Tooltip.RootProps = splittedProps[1] as Tooltip.RootProps;

  return (
    <Tooltip.Root {...tooltipProps}>
      <Tooltip.Trigger class={`outline-0 ${componentProps.triggerClass}`}>
        {componentProps.children}
      </Tooltip.Trigger>
      <Portal>
        <Tooltip.Positioner>
          <Tooltip.Content class={`z-[90]`}>
            <Tooltip.Arrow
              style={{
                '--arrow-size': '7px',
                '--arrow-background': '#232323'
              }}
            >
              <Tooltip.ArrowTip />
            </Tooltip.Arrow>
            {componentProps.content}
          </Tooltip.Content>
        </Tooltip.Positioner>
      </Portal>
    </Tooltip.Root>
  );
};

export default TooltipImpl;
