import { JSXElement, mergeProps } from 'solid-js';
import Request from '~/api/Request.ts';

type CustomerPortalApiConnectorProps = {
  children: JSXElement;
};

export default function CustomerPortalApiConnector(
  props: CustomerPortalApiConnectorProps
): JSXElement {
  const merged = mergeProps({}, props);
  new Request({
    onResponseError: (error) => {
      // Handle unauthorized responses and send to /login
      // handle error
      return Promise.reject(error);
    }
  });
  return <>{merged.children}</>;
}
