import { A, AnchorProps, useLocation, useParams } from '@solidjs/router';
import { getFullPathWithoutParams } from '~/utils/commonUtils.ts';
import { EnvironmentPagePath } from '~/components/AppRouter/utils.ts';

export function Link(props: AnchorProps) {
  const location = useLocation();
  const params = useParams();
  const state: Record<string, any> = {};
  if (
    getFullPathWithoutParams({
      paths: Object.values(EnvironmentPagePath),
      params
    })
  ) {
    // push the current location as previous
    // only if current path is a environment scoped page
    state.previous = location.pathname;
  } else {
    const { previous }: any = location.state || {};
    if (previous) {
      state.previous = previous;
    }
  }
  return (
    <A
      state={state}
      {...props}
    />
  );
}
