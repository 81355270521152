import { JSXElement, mergeProps, Show } from 'solid-js';
import { Localized } from '~/i18n';
import { getTranslationKey } from '~/pages/Authentication/Connections/utils.ts';
import { Button } from '~/components/ui/Button';

type SSOConfigurationHelpButtonProps = {
  isHelpDisplayed: boolean;
  onClickNeedHelp: () => void;
};

export function SSOConfigurationHelpButton(
  props: SSOConfigurationHelpButtonProps
): JSXElement {
  const merged = mergeProps({}, props);
  return (
    <Button
      variant={'outline'}
      size={'sm'}
      onClick={() => merged.onClickNeedHelp()}
    >
      <Show
        when={merged.isHelpDisplayed}
        fallback={
          <Localized translationKey={getTranslationKey('need_help_link')} />
        }
      >
        <Localized translationKey={getTranslationKey('close_need_help_link')} />
      </Show>
    </Button>
  );
}
