export enum FeatureFlagKeys {
  SOCIAL_CONNECTION = 'social_connection',
  MANAGE_ENVIRONMENT = 'manage_environment',
  PORTAL_CUSTOMIZATION = 'portal_customization',
  WEBHOOKS = 'webhooks',
  DIRECTORY_SYNC = 'directory_sync'
}

type FeatureFlagValue = string | boolean;

export interface FeatureFlagData {
  value: FeatureFlagValue;
  key: FeatureFlagKeys;
  reason: string;
  variant: string;
  metadata: Record<string, any>;
}

export interface OpenFeatureListResponse {
  flags: FeatureFlagData[];
}

export type FeatureFlagMap = Record<FeatureFlagKeys, FeatureFlagData> | null;
