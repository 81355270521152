import { JSXElement, mergeProps } from 'solid-js';
import styles from './leff-nav.module.scss';
import { LeftNavLinkConfig } from '~/components/Layout/LeftNav/utils.tsx';
import Link from '~/components/Link';

type IconProps = {
  strokeWidth?: number;
  width?: number;
  class?: string;
};

export default function LeftNavItem(props: LeftNavLinkConfig) {
  const merged = mergeProps({}, props);
  let IconComponent: ((p: IconProps) => JSXElement) | undefined =
    merged.iconComponent;

  const ItemLabelComponent = merged.labelComponent;

  return (
    <li
      classList={{
        'pl-9': !IconComponent
      }}
    >
      <Link
        class={styles.leftNavLink}
        href={merged.href}
        activeClass={`bg-bg-muted`}
      >
        {IconComponent && (
          <IconComponent
            strokeWidth={1}
            width={18}
            class={`mr-1.5`}
          />
        )}

        <ItemLabelComponent />
      </Link>
    </li>
  );
}
