import { JSXElement, Show } from 'solid-js';
import { useQueryClient } from '@tanstack/solid-query';
import { ModalSizes, ModalTypes } from '~/components/ui/Modal/Modal.tsx';
import Modal from '~/components/ui/Modal';
import InputField from '~/components/ui/Forms/InputField.tsx';
import { Localized } from '~/i18n';
import { useAppData } from '~/components/AppData';
import { getLocalizedString } from '~/i18n/utils.ts';
import {
  MemberData,
  MemberDataUpdateRequest,
  updateLoggedInMember,
  updateWorkspace,
  WorkspaceData
} from '~/api';
import Form from '~/components/Form';
import * as zod from 'zod';
import SubmitButton from '~/components/Form/SubmitButton.tsx';
import { validator } from '@felte/validator-zod';
import { cloneObject } from '~/utils/commonUtils.ts';

export default function OnboardingModal(): JSXElement {
  const queryClient = useQueryClient();

  const appData = useAppData();

  // createEffect(() => {
  //   appData.setWorkspaceDisplayName(formData.workspaceDisplayName);
  // });

  const schema = zod.object({
    firstName: zod
      .string()
      .min(
        1,
        getLocalizedString(
          'commons.onboarding.validation_message_empty_first_name'
        )
      ),
    workspaceDisplayName: zod
      .string()
      .min(
        1,
        getLocalizedString(
          'commons.onboarding.validation_message_empty_workspace_name'
        )
      )
  });

  return (
    <Modal
      title={
        <div class={`text-3xl`}>
          <Localized translationKey={`pages.onboarding.header`} />
        </div>
      }
      type={ModalTypes.FORM}
      open={true}
      size={ModalSizes.LARGE}
    >
      {(closeModal) => {
        return (
          <Form
            initialValues={{
              firstName: appData.member?.firstName || '',
              lastName: appData.member?.lastName || '',
              workspaceDisplayName: appData.workspace?.displayName || ''
            }}
            extend={validator({ schema })}
            onSubmit={async ({ firstName, lastName, workspaceDisplayName }) => {
              return new Promise((resolve, reject) => {
                const updatePromise: Array<Promise<any>> = [];

                if (
                  firstName !== appData.member?.firstName ||
                  lastName !== appData.member?.lastName
                ) {
                  const data: MemberDataUpdateRequest = lastName
                    ? { firstName, lastName }
                    : { firstName };

                  updatePromise.push(updateLoggedInMember(data));
                }

                if (workspaceDisplayName !== appData.workspace?.displayName) {
                  updatePromise.push(updateWorkspace(workspaceDisplayName));
                }

                Promise.all([updatePromise])
                  .then(async () => {
                    queryClient.setQueryData(
                      ['logged_in_member_details'],
                      (cachedData: MemberData) => {
                        return {
                          ...cloneObject(cachedData),
                          firstName,
                          lastName
                        };
                      }
                    );
                    queryClient.setQueryData(
                      ['current_workspace_details'],
                      (cachedData: WorkspaceData) => {
                        return {
                          ...cloneObject(cachedData),
                          displayName: workspaceDisplayName
                        };
                      }
                    );
                    closeModal();
                    resolve(true);
                  })
                  .catch((err) => {
                    console.log(err);
                    reject(err);
                  });
              });
            }}
          >
            {({}) => {
              // appData.setWorkspaceDisplayName(data().workspaceDisplayName);
              return (
                <div class={'flex flex-col'}>
                  <p class={`w-full`}>
                    <Localized translationKey={`pages.onboarding.subheader`} />
                  </p>
                  <Show when={!appData.member?.firstName}>
                    <div class={`flex mt-6`}>
                      <div class={`flex-grow`}>
                        <InputField
                          label={getLocalizedString(
                            `input_labels.onboarding_first_name`
                          )}
                          required
                          id={`first_name`}
                          placeholder={getLocalizedString(
                            `input_placeholders.onboarding_first_name`
                          )}
                          name={'firstName'}
                        />
                      </div>
                      <div class={`flex-grow ml-4`}>
                        <InputField
                          label={getLocalizedString(
                            `input_labels.onboarding_last_name`
                          )}
                          id={`last_name`}
                          placeholder={getLocalizedString(
                            `input_placeholders.onboarding_last_name`
                          )}
                          name={'lastName'}
                        />
                      </div>
                    </div>
                  </Show>
                  <Show when={!appData.workspace?.displayName}>
                    <div class={`mt-6`}>
                      <InputField
                        label={getLocalizedString(
                          `input_labels.onboarding_workspace_name`
                        )}
                        id={`workspace_name`}
                        placeholder={getLocalizedString(
                          `input_placeholders.onboarding_workspace_name`
                        )}
                        name={'workspaceDisplayName'}
                        hint={getLocalizedString(
                          `input_hints.onboarding_workspace_name`
                        )}
                      />
                    </div>
                  </Show>

                  <div class={`mt-6 text-center`}>
                    <SubmitButton type={`submit`}>
                      <Localized translationKey={`cta_labels.continue`} />
                    </SubmitButton>
                  </div>
                </div>
              );
            }}
          </Form>
        );
      }}
    </Modal>
  );
}
