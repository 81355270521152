// Typings for the Higher Order Function (HOC)
import { ApiErrorInstance, isApiError } from '~/api/ApiResponse.ts';
import { getLocalizedString } from '~/i18n/utils.ts';

export type HandlerFunctionArgs = {
  error: ApiErrorInstance | Error;
  intl: any; // You might want to replace `any` with a more specific type
};

export type HandlerFunction = (args: HandlerFunctionArgs) => string | undefined;

type WithHandleError = (
  handlerFunction: HandlerFunction
) => (args: HandlerFunctionArgs) => string;

// HOC implementation
const withHandleError: WithHandleError = (handlerFunction) => {
  return (args) => {
    const message = isApiError(args.error) ? handlerFunction(args) : undefined;
    if (message) {
      return message;
    }
    const { error, intl } = args;
    // @todo log exception to cloud
    console.error(error);
    return getLocalizedString('server_errors.generic', {
      intl
    });
  };
};

export { withHandleError };
