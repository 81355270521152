import { JSXElement, Show } from 'solid-js';
import { TriangleAlertIcon } from 'lucide-solid';
import { useAppData } from '~/components/AppData';

const SK_PROD_WORKSPACE_ID = 'wks_67068780714702024';

export default function ScalekitCautionWarning(): JSXElement {
  const appData = useAppData();
  return (
    <Show when={appData.workspace?.id === SK_PROD_WORKSPACE_ID}>
      <div
        class={`py-1 px-3 flex items-center text-xs bg-[#ffbf0130] rounded-md text-[#CE2C31] border border-[#FA0]`}
      >
        <TriangleAlertIcon
          width={16}
          class={`mr-1.5`}
        />
        <span class={`font-semibold`}>Warning:</span> &nbsp; You are in Scalekit
        prod environment. Changes made here will affect live users
      </div>
    </Show>
  );
}
