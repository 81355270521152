import { JSXElement, mergeProps, splitProps } from 'solid-js';
import { Button, ButtonProps } from '~/components/ui/Button';
import { useFormContext } from '~/components/Form/FormContext.tsx';
import { hasFieldError } from '~/components/Form/utils.ts';

type SubmitButtonProps = ButtonProps;

export default function SubmitButton(props: SubmitButtonProps): JSXElement {
  const merged = mergeProps({}, props);
  // excluding properties that are defined in this component
  const [, buttonProps] = splitProps(merged, ['type', 'disabled']);
  const formAccessors = useFormContext();

  return (
    <Button
      {...buttonProps}
      type={'submit'}
      loading={formAccessors.isSubmitting()}
      disabled={
        !formAccessors.isChanged() || hasFieldError(formAccessors.errors())
      }
    >
      {merged.children}
    </Button>
  );
}
