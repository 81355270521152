import { JSXElement, mergeProps, Show } from 'solid-js';
import { CheckIcon } from 'lucide-solid';
import { EnvironmentData, EnvironmentTypes } from '~/api';
import EnvironmentIcon from '~/components/Layout/Header/SwitcherButtons/EnvironmentSwitcher/EnvironmentIcon.tsx';
import { Localized } from '~/i18n';

type EnvironmentSwitcherItemProps = {
  onClick: () => void;
  isActive?: boolean;
  environment: EnvironmentData;
};

export default function EnvironmentSwitcherItem(
  props: EnvironmentSwitcherItemProps
): JSXElement {
  const merged = mergeProps(
    {
      isActive: false
    },
    props
  );
  return (
    <div
      class={`mb-2 px-2 py-2 flex w-full rounded-sm`}
      classList={{
        'font-medium bg-bg-subtle border border-[#e8e8e8]': merged.isActive,
        'font-normal hover:bg-bg-subtle': !merged.isActive
      }}
      onClick={merged.onClick}
    >
      <div class={`flex flex-col font-normal`}>
        <div class={`flex items-center text-sm`}>
          <EnvironmentIcon
            class={`p-1 mr-1`}
            environmentType={merged.environment.type}
          />
          {merged.environment.displayName}
        </div>
        <div class={` mt-0.5`}>
          <span class={`text-xs text-fg-muted`}>
            <Show
              when={merged.environment.type !== EnvironmentTypes.PRODUCTION}
              fallback={
                <Localized translationKey={`commons.environments.production`} />
              }
            >
              <Localized translationKey={`commons.environments.development`} />
            </Show>
          </span>
        </div>
      </div>
      <Show when={merged.isActive}>
        <div class={`self-start ml-auto`}>
          <CheckIcon class={`ml-auto w-[24px] h-[24px] text-fg-default`} />
        </div>
      </Show>
    </div>
  );
}
