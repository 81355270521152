import * as Sentry from '@sentry/browser';

const DSN = import.meta.env.VITE_SENTRY_DSN;
const DSN_HOST = import.meta.env.VITE_SENTRY_DSN_HOST;
const DSN_HOST_PARAM = import.meta.env.VITE_SENTRY_DSN_HOST_PARAM;

const getDSN = () => {
  return `${DSN}@${DSN_HOST}/${DSN_HOST_PARAM}`;
};

function getInitialSentryConfig() {
  return {
    dsn: getDSN(),
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration()
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  };
}

function initialize() {
  Sentry.init(getInitialSentryConfig());
}

function getInitializer() {
  let isInitialized = false;
  return () => {
    if (isInitialized) {
      return;
    }
    initialize();
    isInitialized = true;
  };
}

const initSentry = getInitializer();

function logErrorToSentry(error: Error) {
  Sentry.captureException(error);
}

export { initSentry, logErrorToSentry };
