import {
  createEffect,
  createSignal,
  JSXElement,
  mergeProps,
  splitProps
} from 'solid-js';
import { createForm } from '@felte/solid';
import { createStore } from 'solid-js/store';
import { isEqual } from 'lodash';
import { FormContextProvider } from '~/components/Form/FormContext.tsx';
import { HandlerFunctionArgs } from '~/api';
import { useIntl } from '@cookbook/solid-intl';
import { ServerErrorType } from '~/components/Form/utils.ts';

export type FormRenderFunctionParamType = ReturnType<typeof createForm> & {
  isChanged: () => boolean;
  serverError: ServerErrorType;
  setServerError: (key: string, value: string) => void;
};
type CreateFormConfigType = Parameters<typeof createForm>[0];

type FormWrapperProps = {
  children: (data: FormRenderFunctionParamType) => JSXElement;
  class?: string;
  action?: string;
  method?: 'get' | 'post' | undefined;
  errorHandler?: (args: HandlerFunctionArgs) => string;
};

type FelteFormProps = FormWrapperProps & CreateFormConfigType;

export function FelteForm(props: FelteFormProps): JSXElement {
  const merged = mergeProps(
    {
      action: '#'
    },
    props
  );
  const [wrapperProps, configProps] = splitProps(merged, [
    'children',
    'class',
    'action',
    'method'
  ]);

  const [lastCommittedValues, setLastCommittedValues] = createStore(
    configProps.initialValues
  );
  const [isChanged, setIsChanged] = createSignal(false);
  const [serverError, setServerError] = createStore({
    message: '',
    error: null
  });

  const [propsToBeOverridden, restConfigProps] = splitProps(configProps, [
    'onSubmit',
    'onSuccess',
    'onError'
  ]);

  const intl = useIntl();

  const overriddenConfig = mergeProps(
    {
      onSubmit: async function (data: any, context: any) {
        setServerError('message', '');
        return await propsToBeOverridden.onSubmit?.(data, context);
      },
      onSuccess: async function (response: any, context: any) {
        // This is done to ensure that the form is not dirty anymore
        // and the submit button is disabled again
        setLastCommittedValues(createdForm.data());
        return await propsToBeOverridden.onSuccess?.(response, context);
      },
      onError: async function (error: any, context: any) {
        if (merged.errorHandler) {
          setServerError('message', merged.errorHandler({ error, intl }));
          setServerError('error', error);
        }
        await propsToBeOverridden.onError?.(error, context);
      }
    },
    restConfigProps
  );

  // @todo Overide onSuccess as well to ensure
  // that no requests are re-triggerred when there is an inflight request

  const createdForm = createForm({
    ...overriddenConfig,
    // Though the initial values property is directly coming
    // in the overridden config, still it should be passed
    // from merged object to avoid creating it as a proxy object
    // need to dig further to understand the issue better
    // but should not change this code until then
    initialValues: merged.initialValues
  });
  const { form, data } = createdForm;

  createEffect(() => {
    setIsChanged(!isEqual(lastCommittedValues, data()));
  });

  const combinedRenderProps = {
    ...createdForm,
    isChanged,
    serverError,
    setServerError
  };

  return (
    <FormContextProvider {...combinedRenderProps}>
      <form
        class={wrapperProps.class}
        action={wrapperProps.action}
        method={wrapperProps.method || 'post'}
        ref={form}
      >
        {wrapperProps.children(combinedRenderProps)}
      </form>
    </FormContextProvider>
  );
}
