import { PortalCustomizationSettings } from '~/api/types/customisationData.ts';
import logger from '~/utils/logger';

enum BrandOverrideProperties {
  BUTTON_COLOR = 'buttonColor',
  BUTTON_HOVER_COLOR = 'buttonHoverColor',
  TEXT_COLOR = 'textColor'
}

type ColorOverrideConfig = {
  configKey: BrandOverrideProperties;
  cssVarName: string;
};

const COLOR_OVERRIDE_LIST: ColorOverrideConfig[] = [
  {
    configKey: BrandOverrideProperties.BUTTON_COLOR,
    cssVarName: '--colors-accent-default'
  },
  {
    configKey: BrandOverrideProperties.BUTTON_HOVER_COLOR,
    cssVarName: '--colors-accent-emphasized'
  },
  {
    configKey: BrandOverrideProperties.TEXT_COLOR,
    cssVarName: '--colors-accent-fg'
  }
];

function applyColorOverrides(
  portalCustomizationSettings: PortalCustomizationSettings
) {
  const root = document.documentElement;
  for (const config of COLOR_OVERRIDE_LIST) {
    portalCustomizationSettings[config.configKey] &&
      root.style.setProperty(
        config.cssVarName,
        portalCustomizationSettings[config.configKey]!
      );
  }
}

function applyBrandCustomization(
  portalCustomizationSettings: PortalCustomizationSettings
) {
  logger.info(
    `Applying brand customization for config - `,
    portalCustomizationSettings
  );
  applyColorOverrides(portalCustomizationSettings);
}

export { applyBrandCustomization };
