import * as Dialog from './Components.tsx';

import {
  createEffect,
  createSignal,
  JSXElement,
  mergeProps,
  Show
} from 'solid-js';
import { XIcon } from 'lucide-solid';
import { Button } from '~/components/ui/Button';
import styles from './modal.module.scss';
import { getAppliedClasses } from '~/utils/commonUtils.ts';

export enum ModalTypes {
  FORM = 'form',
  DIALOG = 'dialog'
}

export enum ModalSizes {
  LARGE = 'xl',
  MEDIUM = 'md',
  SMALL = 'sm'
}

type ModalProps = {
  title?: JSXElement;
  subTitle?: JSXElement;
  triggerComponent?: JSXElement;
  children?: JSXElement | ((closeModal: () => void) => JSXElement);
  primaryAction?: JSXElement | string;
  isPrimaryActionLoading?: boolean;
  secondaryAction?: JSXElement | string;
  onPrimaryAction?: (e: Event, closeModal: () => void) => void;
  onSecondaryAction?: () => void;
  open?: boolean;
  onOpenChange?: (isOpen: boolean) => void;
  type?: ModalTypes;
  size?: ModalSizes;
  lazyMount?: boolean;
  unmountOnExit?: boolean;
  showCloseIcon?: boolean;
};

export default function Modal(props: ModalProps) {
  const merged: ModalProps = mergeProps(
    {
      isPrimaryActionLoading: false,
      onPrimaryAction: () => {},
      onSecondaryAction: () => {},
      type: ModalTypes.DIALOG,
      size: ModalSizes.MEDIUM,
      lazyMount: true,
      unmountOnExit: true,
      showCloseIcon: false
    },
    props
  );
  const [openModal, setOpenModal] = createSignal(merged.open);

  createEffect(() => {
    setOpenModal(merged.open);
  });

  function closeModal() {
    setOpenModal(false);
  }

  return (
    <Dialog.Root
      {...merged}
      open={openModal()}
      onOpenChange={({ open }) => {
        props.onOpenChange && props.onOpenChange(open);
        setOpenModal(open);
      }}
      closeOnInteractOutside={false}
      closeOnEscapeKeyDown={false}
    >
      <Show when={merged.triggerComponent}>
        <Dialog.Trigger asChild>{merged.triggerComponent}</Dialog.Trigger>
      </Show>
      <Dialog.Backdrop />
      <Dialog.Positioner>
        <Dialog.Content
          class={getAppliedClasses({
            [styles.modalSm]: merged.size === ModalSizes.SMALL,
            [styles.modalMd]: merged.size === ModalSizes.MEDIUM,
            [styles.modalXl]: merged.size === ModalSizes.LARGE
          })}
        >
          <div class={`p-7 flex flex-col`}>
            <Show when={merged.title || merged.showCloseIcon}>
              <div class={`flex justify-between`}>
                <Show when={merged.title}>
                  <Dialog.Title class={'flex-grow'}>
                    {merged.title}
                  </Dialog.Title>
                </Show>
                <Show when={merged.showCloseIcon}>
                  <Button
                    variant={`ghost`}
                    size={'xs'}
                    onClick={closeModal}
                  >
                    <XIcon />
                  </Button>
                </Show>
              </div>
            </Show>
            <Dialog.Description>
              <Show when={merged.subTitle}>
                <p class={`w-full mt-1 text-sm`}>{merged.subTitle}</p>
              </Show>
              <div class={`w-full mt-6`}>
                {typeof merged.children === 'function'
                  ? merged.children(closeModal)
                  : merged.children}
              </div>
            </Dialog.Description>
            <Show when={merged.type === ModalTypes.DIALOG}>
              <div class={`flex justify-end mt-6`}>
                {merged.secondaryAction && (
                  <Dialog.CloseTrigger
                    class={`mr-2`}
                    asChild
                  >
                    <Button
                      variant={`outline`}
                      size={'sm'}
                    >
                      {merged.secondaryAction}
                    </Button>
                  </Dialog.CloseTrigger>
                )}
                {merged.primaryAction && (
                  <Button
                    size={'sm'}
                    loading={merged.isPrimaryActionLoading}
                    onClick={(e) => {
                      merged.onPrimaryAction &&
                        merged.onPrimaryAction(e, closeModal);
                    }}
                  >
                    {merged.primaryAction}
                  </Button>
                )}
              </div>
            </Show>
          </div>
        </Dialog.Content>
      </Dialog.Positioner>
    </Dialog.Root>
  );
}
