export enum DirectoryTypes {
  SCIM = 'SCIM'
}

export enum DirectoryProviders {
  ONELOGIN = 'ONELOGIN',
  OKTA = 'OKTA',
  MICROSOFT_AD = 'MICROSOFT_AD',
  CUSTOM = 'DIRECTORY_PROVIDER_UNSPECIFIED'
}

export enum DirectoryStatuses {
  INPROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  UNSPECIFIED = 'DIRECTORY_STATUS_UNSPECIFIED'
}

export enum DerivedDirectoryStatuses {
  READY_TO_SETUP = 'READY_TO_SETUP',
  LISTENING_FOR_EVENTS = 'LISTENING_FOR_EVENTS',
  DIRECTORY_LINKED = 'DIRECTORY_LINKED'
}

export interface DirectoryData {
  id: string;
  name: string;
  directoryType: DirectoryTypes;
  directoryEndpoint: string;
  organizationId: string;
  source: string;
  enabled: boolean;
  directoryProvider: DirectoryProviders;
  status: DirectoryStatuses;
  mappings: Array<Record<string, any>>;
  lastSyncedAt?: string;
  totalUsers: number;
  totalGroups: number;
}

export interface CreateDirectoryRequest {
  directoryType: DirectoryTypes;
  directoryProvider: DirectoryProviders;
}

export interface ListDirectoryResponse {
  directories: DirectoryData[];
}

export interface CreateDirectoryResponse {
  directory: DirectoryData;
}

export interface GetDirectoryByIdResponse {
  directory: DirectoryData;
}

export interface DirectoryGroup {
  id: string;
  displayName: string;
  totalUsers: number;
  lastUpdatedAt: string;
}

export interface DirectoryUser {
  id: string;
  email: string;
  preferredUsername: string;
  givenName: string;
  familyName: string;
  emails: string[];
  groups: DirectoryGroup[];
  lastUpdatedAt: string;
}

export interface ListDirectoryUserRequest {
  organizationId: string;
  directoryId: string;
  pageSize: number;
  pageToken: string;
}

export interface ListDirectoryUserResponse {
  totalSize: number;
  nextPageToken: number;
  prevPageToken: number;
  users: DirectoryUser[];
}

export interface ListDirectoryGroupRequest {
  organizationId: string;
  directoryId: string;
  pageSize: number;
  pageToken: string;
}

export interface ListDirectoryGroupResponse {
  totalSize: number;
  nextPageToken: number;
  prevPageToken: number;
  groups: DirectoryGroup[];
}
