import { ConfirmModalValues } from '~/components/ui/Modal/ConfirmModalContext.tsx';
import {
  ApiErrorInstance,
  deleteOrganizationById,
  getOrganizationsKey,
  getOrganizationsSearchKey,
  isApi5xxResponseStatus,
  OrganizationData,
  withHandleError
} from '~/api';
import { QueryClient } from '@tanstack/solid-query';
import { DomainData } from '~/api/types/domainData.ts';
import { getFormattedDate, getLocalizedString } from '~/i18n/utils.ts';
import { HttpStatusCode } from 'axios';
import { ToastContextValue } from '~/components/ui/Toast';
import { ColumnDef } from '@tanstack/solid-table';
import OrganizationListItemActions from '~/pages/Organization/OrganizationListItemActions.tsx';
import { MIN_SEARCH_CHARS } from '~/pages/Login/utils.ts';
import { ConnectionData, Providers } from '~/api/types/connectionData.ts';
import InfoIcon from '~/components/ui/InfoIcon';
import { Localized } from '~/i18n';
import { Show } from 'solid-js';

const TRANSLATION_BASE_PATH = `pages.organizations`;

const getTranslationKey = (translationStr: string): string =>
  `${TRANSLATION_BASE_PATH}.${translationStr}`;

const ORG_LIST_PAGE_SIZE = 20;

enum ORGANIZATION_TAB_TYPES {
  OVERVIEW = 'overview',
  SSO_CONNECTIONS = 'sso',
  DIRECTORY_SYNC = 'directory-sync'
}

type DeleteOrganisationWithConfirmationParamType = {
  confirm: ConfirmModalValues;
  title: string;
  description: string;
  primaryActionLabel: string;
  org: OrganizationData;
  queryClient: QueryClient;
  toaster: ToastContextValue;
  intl: any;
};

function deleteOrganisationWithConfirmation({
  confirm,
  title,
  description,
  primaryActionLabel,
  org,
  queryClient,
  toaster,
  intl
}: DeleteOrganisationWithConfirmationParamType): Promise<boolean> {
  return new Promise((resolve, reject) => {
    confirm.show({
      title,
      description,
      primaryActionLabel: primaryActionLabel,
      onConfirm: () => {
        confirm.setIsLoading(true);
        deleteOrganizationById(org.id!)
          .then(async () => {
            confirm.setIsLoading(false);
            confirm.close();
            await queryClient.invalidateQueries({
              queryKey: getOrganizationsKey(),
              type: 'all',
              exact: true
            });
            await queryClient.invalidateQueries({
              queryKey: getOrganizationsSearchKey(),
              type: 'all'
            });
            resolve(true);
          })
          .catch((error) => {
            confirm.setIsLoading(false);
            confirm.close();
            toaster.toast().error({
              description: handleDeleteOrganizationErrors({ error, intl })
            });
            // @todo handle error
            reject(error);
          });
      }
    });
  });
}

function getOrganizationDomainsFromResponse(
  orgDomainQueryResponse: { domains: DomainData[] } | undefined
): DomainData[] {
  return orgDomainQueryResponse && orgDomainQueryResponse.domains
    ? orgDomainQueryResponse.domains
    : [];
}

function shouldSearch(query: string): boolean {
  return query?.length >= MIN_SEARCH_CHARS;
}

const getOrgListColumnDef = ({
  intl,
  onDeleteOrg,
  onEditOrg
}: {
  intl: any;
  onDeleteOrg: (o: string) => void;
  onEditOrg: (o: OrganizationData) => void;
}): ColumnDef<OrganizationData>[] => [
  {
    header: getLocalizedString(`table_headers.organization_id`, { intl }),
    accessorKey: 'id',
    cell: (info) => info.getValue(),
    meta: {
      class: 'w-4/12' // additional meta to control column width
    }
  },
  {
    header: getLocalizedString(`table_headers.organization_name`, { intl }),
    accessorKey: 'displayName',
    cell: (info) => {
      const orgDisplayName = info.getValue() as string;
      return (
        <span class={`flex items-center`}>
          {orgDisplayName}
          <Show when={isTestOrganization(info.row.original)}>
            <InfoIcon
              class={`ml-1.5 text-fg-muted`}
              tooltipContent={
                <Localized
                  translationKey={getTranslationKey(
                    'org_list_test_org_info_text'
                  )}
                />
              }
            />
          </Show>
        </span>
      );
    },
    meta: {
      class: 'w-4/12' // additional meta to control column width
    }
  },
  {
    header: getLocalizedString(`table_headers.organization_created_date`, {
      intl
    }),
    accessorKey: 'createTime',
    cell: (info) => getFormattedDate(info.getValue() as string),
    meta: {
      class: 'w-3/12' // additional meta to control column width
    }
  },
  {
    header: '',
    id: 'actions',
    accessorKey: 'id',
    cell: (info) => (
      <OrganizationListItemActions
        org={info.row.original}
        onDeleteOrg={onDeleteOrg}
        onEditOrg={onEditOrg}
        disabled={isTestOrganization(info.row.original)}
      />
    ),
    meta: {
      class: 'w-1/12' // additional meta to control column width
    }
  }
];

const isTestOrganization = (org: OrganizationData): boolean => {
  return org.metadata?.testOrganization === 'true';
};

const getTestConnectionFromConnectionList = (connections: ConnectionData[]) => {
  return connections.find((c) => c.provider === Providers.IDP_SIMULATOR);
};

const errorTranslationBasePath = 'server_errors.organizations';

const handleAddOrganizationErrors = withHandleError(({ error, intl }) => {
  const serverError = error as ApiErrorInstance;
  if (serverError.httpStatus === HttpStatusCode.BadRequest) {
    return getLocalizedString(`${errorTranslationBasePath}.create_failed`, {
      intl
    });
  }
});

const handleEditOrganizationErrors = withHandleError(({ error, intl }) => {
  const serverError = error as ApiErrorInstance;
  if (serverError.httpStatus === HttpStatusCode.BadRequest) {
    return getLocalizedString(`${errorTranslationBasePath}.update_failed`, {
      intl
    });
  }
});

const handleDeleteOrganizationErrors = withHandleError(({ error, intl }) => {
  const serverError = error as ApiErrorInstance;
  if (serverError.httpStatus === HttpStatusCode.BadRequest) {
    return getLocalizedString(`${errorTranslationBasePath}.delete_failed`, {
      intl
    });
  }
});

const handleAddDomainErrors = withHandleError(({ error, intl }) => {
  const serverError = error as ApiErrorInstance;
  if (serverError.httpStatus === HttpStatusCode.Conflict) {
    return getLocalizedString(
      `${errorTranslationBasePath}.domain_add_duplicate_error`,
      {
        intl
      }
    );
  }
  if (
    serverError.httpStatus === HttpStatusCode.BadRequest ||
    isApi5xxResponseStatus(serverError.httpStatus)
  ) {
    return getLocalizedString(`${errorTranslationBasePath}.domain_add_failed`, {
      intl
    });
  }
});

const handleDeleteDomainErrors = withHandleError(({ error, intl }) => {
  const serverError = error as ApiErrorInstance;
  if (
    serverError.httpStatus === HttpStatusCode.BadRequest ||
    isApi5xxResponseStatus(serverError.httpStatus)
  ) {
    return getLocalizedString(
      `${errorTranslationBasePath}.domain_delete_failed`,
      {
        intl
      }
    );
  }
});

export {
  getTranslationKey,
  ORG_LIST_PAGE_SIZE,
  ORGANIZATION_TAB_TYPES,
  deleteOrganisationWithConfirmation,
  getOrganizationDomainsFromResponse,
  shouldSearch,
  getOrgListColumnDef,
  isTestOrganization,
  getTestConnectionFromConnectionList,
  handleAddOrganizationErrors,
  handleEditOrganizationErrors,
  handleDeleteOrganizationErrors,
  handleAddDomainErrors,
  handleDeleteDomainErrors
};
