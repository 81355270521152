import { createSignal, JSXElement, mergeProps } from 'solid-js';
import Modal, { ModalTypes } from './Modal.tsx';
import { getLocalizedString } from '~/i18n/utils.ts';
import { Button } from '~/components/ui/Button';
import { Localized } from '~/i18n';

export type ConfirmModalPrimaryActionVariants = 'primary' | 'danger';

type ConfirmModalProps = {
  title: JSXElement;
  description: JSXElement;
  children: JSXElement;
  primaryActionLabel?: JSXElement;
  open: boolean;
  onConfirm: () => any;
  onCancel?: () => void;
  onOpenChange?: (isOpen: boolean) => void;
  isLoading?: boolean;
  primaryActionVariant: ConfirmModalPrimaryActionVariants;
};

export default function ConfirmModal(props: ConfirmModalProps): JSXElement {
  const merged = mergeProps(
    {
      isLoading: false,
      primaryActionLabel: getLocalizedString('cta_labels.proceed'),
      primaryActionVariant: 'danger'
    },
    props
  );

  const [isSubmitting, setIsSubmitting] = createSignal(false);

  return (
    <>
      <Modal
        title={merged.title}
        type={ModalTypes.FORM}
        open={merged.open}
        onPrimaryAction={async () => {
          await merged.onConfirm();
        }}
        onOpenChange={(open) => merged.onOpenChange?.(open)}
      >
        {(closeModal) => (
          <>
            <div>
              <p>{merged.description}</p>
            </div>
            <div class={`flex justify-end mt-6`}>
              <Button
                variant={`outline`}
                size={'sm'}
                onClick={() => {
                  closeModal();
                  merged.onCancel?.();
                }}
                class={`mr-2`}
                data-testid={`confirm-modal-cancel`}
              >
                <Localized translationKey={'cta_labels.cancel'} />
              </Button>
              <Button
                type={'button'}
                size={'sm'}
                onClick={async () => {
                  setIsSubmitting(true);
                  await merged.onConfirm();
                  setIsSubmitting(false);
                }}
                loading={merged.isLoading || isSubmitting()}
                color={merged.primaryActionVariant}
                data-testid={`confirm-modal-primary-action`}
              >
                {merged.primaryActionLabel}
              </Button>
            </div>
          </>
        )}
      </Modal>
      <>{merged.children}</>
    </>
  );
}
