import { Component, JSXElement, mergeProps } from 'solid-js';
import AdminPortalTabList from '~/pages/AdminPortal/AdminPortalTabList.tsx';
import {
  getWorkspacePageComponent,
  LayoutTypes
} from '~/components/Layout/utils.tsx';

type AdminPortalRendererProps = {
  children: JSXElement;
};

function AdminPortalRenderer(props: AdminPortalRendererProps): JSXElement {
  const merged = mergeProps({}, props);
  return <AdminPortalTabList>{merged.children}</AdminPortalTabList>;
}

export default function withAdminPortalRenderer(
  component: Component,
  layout: LayoutTypes = LayoutTypes.ADMIN_PORTAL
): Component {
  const Renderer = component;
  return getWorkspacePageComponent(
    () => (
      <AdminPortalRenderer>
        <Renderer />
      </AdminPortalRenderer>
    ),
    layout
  );
}
