import { JSXElement, Match, mergeProps, Switch } from 'solid-js';
import { CustomerPortalPageTypes, LayoutTypes, NavTypes } from './utils.tsx';
import { TwoColumn } from './TwoColumn.tsx';
import AdminPortalLayout from './AdminPortalLayout.tsx';

type LayoutProps = {
  children: JSXElement;
  layoutType: LayoutTypes;
};

export default function Layout(props: LayoutProps): JSXElement {
  const merged = mergeProps(
    {
      layoutType: LayoutTypes.EMPTY_CANVAS
    },
    props
  );
  return (
    <Switch>
      <Match when={merged.layoutType === LayoutTypes.TWO_COLUMN_WITH_NAV}>
        <TwoColumn navType={NavTypes.TOP_AND_LEFT}>{merged.children}</TwoColumn>
      </Match>
      <Match when={merged.layoutType === LayoutTypes.TWO_COLUMN_WITHOUT_NAV}>
        <TwoColumn navType={NavTypes.LEFT_ONLY}>{merged.children}</TwoColumn>
      </Match>
      <Match when={merged.layoutType === LayoutTypes.ADMIN_PORTAL}>
        <AdminPortalLayout>{merged.children}</AdminPortalLayout>
      </Match>
      <Match when={merged.layoutType === LayoutTypes.ADMIN_PORTAL_DOC}>
        <AdminPortalLayout pageType={CustomerPortalPageTypes.DOCUMENTATION}>
          {merged.children}
        </AdminPortalLayout>
      </Match>
      <Match when={merged.layoutType === LayoutTypes.EMPTY_CANVAS}>
        {merged.children}
      </Match>
    </Switch>
  );
}
