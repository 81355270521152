import LeftNav from './LeftNav';
import RightContent from '~/components/Layout/RightContent/index.js';
import Header from '~/components/Layout/Header';
import { JSXElement, mergeProps, Show } from 'solid-js';
import styles from './layout.module.scss';
import { NavTypes } from '~/components/Layout/utils.tsx';
import { LeftNavType } from '~/components/Layout/LeftNav/utils.tsx';

type TwoColumnProps = {
  children: JSXElement;
  navType?: NavTypes;
};
export function TwoColumn(props: TwoColumnProps) {
  const merged = mergeProps(
    {
      navType: NavTypes.TOP_AND_LEFT
    },
    props
  );

  const shouldShowTopNav = () => merged.navType === NavTypes.TOP_AND_LEFT;
  const getLeftNavType = (): LeftNavType => {
    switch (merged.navType) {
      case NavTypes.TOP_AND_LEFT:
        return LeftNavType.ENVIRONMENT;
      case NavTypes.LEFT_ONLY:
        return LeftNavType.WORKSPACE;
      default:
        return LeftNavType.ENVIRONMENT;
    }
  };

  return (
    <div class={`w-full`}>
      <Show when={shouldShowTopNav()}>
        <Header />
      </Show>
      <main
        class={styles.twoColumnMain}
        classList={{
          [styles.withoutTopNav]: !shouldShowTopNav()
        }}
      >
        <LeftNav type={getLeftNavType()} />
        <RightContent>{props.children}</RightContent>
      </main>
    </div>
  );
}
