export enum AppErrors {
  CUSTOMER_PORTAL_LINK_EXPIRED = 'CUSTOMER_PORTAL_LINK_EXPIRED',
  INVALID_PORTAL_CUSTOMISATION_CONFIG = 'INVALID_PORTAL_CUSTOMISATION_CONFIG',
  FAILED_TO_LOAD_SOCIAL_CONNCETION = 'FAILED_TO_LOAD_SOCIAL_CONNCETION',
  FAILED_TO_LOAD_FEATURE_FLAG = 'FAILED_TO_LOAD_FEATURE_FLAG',
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  TOKEN_EXCHANGE_FAILED = 'TOKEN_EXCHANGE_FAILED',
  LINK_EXPIRED = 'LINK_EXPIRED',
  INVALID_PORTAL_TYPE_IN_URL = 'INVALID_PORTAL_TYPE_IN_URL'
}

export const mergeEmailParts = (emailParts: Array<string>) =>
  emailParts.join('@');
