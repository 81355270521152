import {
  Component,
  createEffect,
  JSXElement,
  mergeProps,
  Show,
  untrack
} from 'solid-js';
import { OrgDetailsDataType } from '~/components/AppRouter/utils.ts';
import SkLoader from '~/components/ui/SkLoader';
import OrganizationContextProvider, {
  useOrganizationContext
} from '~/components/AppData/OrganizationContext.tsx';
import { useNeedHelpContext } from '~/components/Layout/NeedHelp/NeedHelpContext.tsx';
import { getIdpDocLink } from '~/pages/Authentication/Connections/utils.ts';

type AdminPortalWithOrganizationContextProps = {
  children: JSXElement;
  type: OrgDetailsDataType;
};

function ComponentWithNeedHelpContextData(props: { children: JSXElement }) {
  const merged = mergeProps({}, props);
  const orgData = useOrganizationContext();
  const needHelpContext = useNeedHelpContext();

  createEffect(() => {
    if (orgData.selectedConnection && orgData.selectedConnection?.provider) {
      untrack(() => {
        needHelpContext.setShouldShowHelp(true);
        needHelpContext.setHelpDocLink(
          getIdpDocLink(orgData.selectedConnection!)
        );
      });
    } else {
      untrack(() => {
        needHelpContext.setShouldShowHelp(false);
        needHelpContext.setHelpDocLink('');
      });
    }
  });

  return <>{merged.children}</>;
}

function AdminPortalWithOrganizationContext(
  props: AdminPortalWithOrganizationContextProps
): JSXElement {
  const merged = mergeProps({}, props);
  return (
    <OrganizationContextProvider type={merged.type}>
      {(isDataLoaded) => (
        <Show
          when={isDataLoaded}
          fallback={<SkLoader />}
        >
          <ComponentWithNeedHelpContextData>
            {merged.children}
          </ComponentWithNeedHelpContextData>
        </Show>
      )}
    </OrganizationContextProvider>
  );
}

export default function withOrganizationContext(
  component: Component,
  type: OrgDetailsDataType
) {
  const Renderer = component;
  return () => (
    <AdminPortalWithOrganizationContext type={type}>
      <Renderer />
    </AdminPortalWithOrganizationContext>
  );
}
