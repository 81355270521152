import { JSXElement, mergeProps, Show } from 'solid-js';
import { Localized } from '~/i18n';

export enum LINK_EXPIRED_CONTEXT {
  ADMIN_PORTAL_CONTEXT = 'ADMIN_PORTAL_CONTEXT',
  GENERIC_CONTEXT = 'GENERIC_CONTEXT'
}

type PortalLinkExpiredProps = {
  context?: LINK_EXPIRED_CONTEXT;
};

export default function PortalLinkExpired(
  props?: PortalLinkExpiredProps
): JSXElement {
  const merged = mergeProps({}, props);
  return (
    <div class={`flex flex-col w-full justify-center items-center`}>
      <div class={`text-2xl`}>
        <Show
          when={merged.context === LINK_EXPIRED_CONTEXT.ADMIN_PORTAL_CONTEXT}
          fallback={
            <Localized
              translationKey={`pages.link_expired.linked_expired_header`}
            />
          }
        >
          <Localized
            translationKey={`pages.link_expired.admin_portal_linked_expired_header`}
          />
        </Show>
      </div>
      <div class={`mt-9`}>
        <Localized
          translationKey={`pages.link_expired.linked_expired_description`}
        />
      </div>
    </div>
  );
}
