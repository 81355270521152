import { Component, JSXElement } from 'solid-js';
import Layout from './Layout.tsx';

enum LayoutTypes {
  TWO_COLUMN_WITH_NAV = 'TWO_COLUMN_WITH_NAV',
  TWO_COLUMN_WITHOUT_NAV = 'TWO_COLUMN_WITHOUT_NAV',
  EMPTY_CANVAS = ' EMPTY_CANVAS',
  ADMIN_PORTAL = 'ADMIN_PORTAL',
  ADMIN_PORTAL_DOC = 'ADMIN_PORTAL_DOC'
}

export enum NavTypes {
  TOP_AND_LEFT = 'TOP_AND_LEFT',
  LEFT_ONLY = 'LEFT_ONLY'
}

export enum CustomerPortalPageTypes {
  SSO_CONFIGURATION = 'SSO_CONFIGURATION',
  DOCUMENTATION = 'DOCUMENTATION'
}

/*
 * All Page components that need to work under the workspace namespace
 * should be generated using this higher order function
 * Any future providers relevant for workspace namespace
 * should be included in this template
 */
const getWorkspacePageComponent = (
  pageComponent: Component,
  layoutType: LayoutTypes
): Component => {
  const PageComponent = pageComponent;
  return (): JSXElement => (
    <Layout layoutType={layoutType}>
      <PageComponent />
    </Layout>
  );
};

export { LayoutTypes, getWorkspacePageComponent };
