import { JSXElement } from 'solid-js';
import { Button } from '~/components/ui/Button';

type BaseProps = {
  children: JSXElement;
};
export default function Base(props: BaseProps): JSXElement {
  return (
    <Button
      variant={`ghost`}
      size={`sm`}
      class={`font-normal px-1.5 text-fg-default hover:bg-bg-muted active:bg-bg-emphasized`}
    >
      {props.children}
    </Button>
  );
}
