import { createContext, JSXElement, mergeProps, useContext } from 'solid-js';
import { createStore } from 'solid-js/store';

const NeedHelpContext = createContext();

type NeedHelpContextValueType = {
  shouldShowHelp: boolean;
  setShouldShowHelp: (shouldShow: boolean) => void;
  helpDocLink: string;
  setHelpDocLink: (link: string) => void;
};

type NeedHelpContextProps = {
  children: JSXElement;
};

export default function NeedHelpContextProvider(
  props: NeedHelpContextProps
): JSXElement {
  const merged = mergeProps({}, props);
  const [data, setData] = createStore<NeedHelpContextValueType>({
    shouldShowHelp: false,
    setShouldShowHelp: (shouldShow: boolean) =>
      setData('shouldShowHelp', shouldShow),
    helpDocLink: '',
    setHelpDocLink: (link) => setData('helpDocLink', link)
  });

  return (
    <NeedHelpContext.Provider value={data}>
      {merged.children}
    </NeedHelpContext.Provider>
  );
}

const useNeedHelpContext = (): NeedHelpContextValueType =>
  useContext(NeedHelpContext) as NeedHelpContextValueType;

export { useNeedHelpContext };
