import { Component, createEffect, Show } from 'solid-js';
import { useLocation, useNavigate } from '@solidjs/router';
import OnboardingModal from '~/components/OnboardingModal';
import { useAppData } from '~/components/AppData';
import { getSlug, PublicPagePath } from '~/components/AppRouter/utils.ts';

export default function withSession(component: Component): Component {
  const Renderer = component;
  return () => {
    const appData = useAppData();
    const location = useLocation();
    const navigate = useNavigate();

    createEffect(() => {
      if (!appData) {
        // Someone accesses the base path `/`
        navigate(`${getSlug.workspaceBaseNamespace()}`, {
          replace: true
        });
      } else if (!appData.isLoggedIn) {
        const urlParams = new URLSearchParams({
          redirectTo: location.pathname
        });
        navigate(
          `${getSlug.workspaceBaseNamespace()}${getSlug.publicPage(PublicPagePath.LOGIN)}?${urlParams.toString()}`,
          {
            replace: true
          }
        );
      }
    });

    function shouldShowOnboarding() {
      return (
        (!!appData.member && !appData.member.firstName) ||
        (!!appData.workspace && !appData.workspace.displayName)
      );
    }

    return (
      <Show when={appData?.isLoggedIn}>
        {shouldShowOnboarding() && <OnboardingModal />}
        <Renderer />
      </Show>
    );
  };
}
